// Models
import { EquipmentCategory } from '@configuration/equipment-categories/models';
import { EquipmentModel } from '@configuration/equipment-models/models';
import { EquipmentType } from '@configuration/equipment-types/models';
import { Status } from '@configuration/status/models';
import { Customer } from '@customers/customers-mgmt/models';
import { Device } from '@equipments/devices-mgmt/models';
import { Equipment } from '@equipments/equipments-mgmt/models';
import { IEquipmentIssue } from './equipment-issue.interface';
import { StatusMapHelper } from '../helpers/status-map.helper';
import { IEquipmentRecommendation } from './equipment-recommendation.interface';
import { formatDate, formatNumber } from '@angular/common';
import { IEquipmentStatistics } from './equipment-statistics.interface';

export class MapEquipment {
  id: number = 0;
  version: number | undefined;
  serial: string | undefined;
  observations: string | undefined;
  latitude: number = 0;
  longitude: number = 0;

  customerId: number = 0;
  deviceId: number = 0;
  equipmentModelId: number = 0;
  equipmentCategoryId: number = 0;
  equipmentTypeId: number = 0;
  statusId: number = 0;

  customer: Customer = new Customer();
  device: Device = new Device();
  equipmentModel: EquipmentModel = new EquipmentModel();
  equipmentCategory: EquipmentCategory = new EquipmentCategory();
  equipmentType: EquipmentType = new EquipmentType();
  status: Status = new Status();
  statistics: IEquipmentStatistics | undefined;

  // TODO: No sé porque uso este atributo aquí
  postalCode: string | undefined;

  accesory1: string | undefined;
  accesory2: string | undefined;
  accesory3: string | undefined;
  accesory4: string | undefined;
  accesory5: string | undefined;

  description1: string | undefined;
  description2: string | undefined;
  description3: string | undefined;
  description4: string | undefined;
  description5: string | undefined;

  reference1: string | undefined;
  reference2: string | undefined;
  reference3: string | undefined;
  reference4: string | undefined;
  reference5: string | undefined;

  alertCommunications: boolean | undefined;
  alertCommunicationsAt: Date | undefined;
  alertDamageServo: boolean | undefined;
  alertDamageServoAt: Date | undefined;
  alertDumped: boolean | undefined;
  alertDumpedAt: Date | undefined;
  alertFire: boolean | undefined;
  alertFireAt: Date | undefined;
  alertLocked: boolean | undefined;
  alertLockedAt: Date | undefined;
  alertNoBattery: boolean | undefined;
  alertNoBatteryAt: Date | undefined;
  alertOpenCover: boolean | undefined;
  alertOpenCoverAt: Date | undefined;

  recomendationChangeBattery: boolean | undefined;
  recomendationChangeBatteryAt: Date | undefined;
  recomendationChangeLocation: boolean | undefined;
  recomendationChangeLocationAt: Date | undefined;

  issues: IEquipmentIssue[] | undefined;
  recommendations: IEquipmentRecommendation[] | undefined;

  constructor(data: Equipment) {
    this.id = data.id;
    this.version = data.version;
    this.serial = data.serial;
    this.observations = data.observations;
    this.latitude = data.latitude;
    this.longitude = data.longitude;

    this.postalCode = data.postalCode;

    this.accesory1 = data.accesory1;
    this.accesory2 = data.accesory2;
    this.accesory3 = data.accesory3;
    this.accesory4 = data.accesory4;
    this.accesory5 = data.accesory5;
    this.description1 = data.description1;
    this.description2 = data.description2;
    this.description3 = data.description3;
    this.description4 = data.description4;
    this.description5 = data.description5;
    this.reference1 = data.reference1;
    this.reference2 = data.reference2;
    this.reference3 = data.reference3;
    this.reference4 = data.reference4;
    this.reference5 = data.reference5;

    this.alertCommunications = data.alertCommunications;
    this.alertCommunicationsAt = data.alertCommunicationsAt;
    this.alertDamageServo = data.alertDamageServo;
    this.alertDamageServoAt = data.alertDamageServoAt;
    this.alertDumped = data.alertDumped;
    this.alertDumpedAt = data.alertDumpedAt;
    this.alertFire = data.alertFire;
    this.alertFireAt = data.alertFireAt;
    this.alertLocked = data.alertLocked;
    this.alertLockedAt = data.alertLockedAt;
    this.alertNoBattery = data.alertNoBattery;
    this.alertNoBatteryAt = data.alertNoBatteryAt;
    this.alertOpenCover = data.alertOpenCover;
    this.alertOpenCoverAt = data.alertOpenCoverAt;

    this.recomendationChangeBattery = data.recomendationChangeBattery;
    this.recomendationChangeBatteryAt = data.recomendationChangeBatteryAt;
    this.recomendationChangeLocation = data.recomendationChangeLocation;
    this.recomendationChangeLocationAt = data.recomendationChangeLocationAt;

    this.status = data.status;
    this.customer = data.customer;
    this.device = data.device;
    this.equipmentModel = data.equipmentModel;
    this.equipmentCategory = data.equipmentCategory;
    this.equipmentType = data.equipmentType;

    // this.setDummyData();

    this.processIssues();
    this.processRecommendations();
  }

  public getImageName() {
    return `model-${this.equipmentModel.id}-load-${this.equipmentType.id}-cat-${this.equipmentCategory.id}.png`;
  }

  private setDummyData() {

    StatusMapHelper.setDummyDataItemMap(this);

  }


  private processIssues() {
    this.issues = [];

    if (this.alertCommunications) {
      this.issues.push({
        iconName: 'alert-communications',
        key: 'communications',
        label: 'No comunica',
        date: this.alertCommunicationsAt ? new Date(this.alertCommunicationsAt) : null,
      });
    }

    if (this.alertNoBattery) {
      this.issues.push({
        iconName: 'alert-no-battery',
        key: 'no-battery',
        label: 'Sin batería',
        date: this.alertNoBatteryAt ? new Date(this.alertNoBatteryAt) : null,
      });
    }

    if (this.alertDumped) {
      this.issues.push({
        iconName: 'alert-dumped',
        key: 'dumped',
        label: 'Contenedor volcado',
        date: this.alertDumpedAt ? new Date(this.alertDumpedAt) : null,
      });
    }

    if (this.alertLocked) {
      this.issues.push({
        iconName: 'alert-locked',
        key: 'locked',
        label: 'No abre',
        date: this.alertLockedAt ? new Date(this.alertLockedAt) : null,
      });
    }

    if (this.alertFire) {
      this.issues.push({
        iconName: 'alert-fire',
        key: 'fire',
        label: 'Incendio',
        date: this.alertFireAt ? new Date(this.alertFireAt) : null,
      });
    }

    if (this.alertDamageServo) {
      this.issues.push({
        iconName: 'alert-damage-servo',
        key: 'damage-servo',
        label: 'Servo estropeado',
        date: this.alertDamageServoAt ? new Date(this.alertDamageServoAt) : null,
      });
    }

    if (this.alertOpenCover) {
      this.issues.push({
        iconName: 'alert-open-cover',
        key: 'open-cover',
        label: 'Tapa abierta',
        date: this.alertOpenCoverAt ? new Date(this.alertOpenCoverAt) : null,
      });
    }
  }

  private processRecommendations() {
    this.recommendations = [];

    if (this.recomendationChangeBattery) {
      this.recommendations.push({
        iconName: 'recommendation-change-battery',
        key: 'change-battery',
        label: 'Cambio de batería',
        date: this.recomendationChangeBatteryAt ? new Date(this.recomendationChangeBatteryAt) : null,
      });
    }

    if (this.recomendationChangeLocation) {
      this.recommendations.push({
        iconName: 'recommendation-change-location',
        key: 'change-location',
        label: 'Cambio de ubicación',
        date: this.recomendationChangeLocationAt ? new Date(this.recomendationChangeLocationAt) : null,
      });
    }

  }
}
