import { Injectable } from '@angular/core';

@Injectable()
export class DataTypeHelper {

  public static convertDates<T extends Record<string, any>>(obj: T): T {
    return Object.keys(obj).reduce((acc, key) => {
      const value = obj[key];

      // Si el valor es una cadena que puede ser convertida a fecha
      if (typeof value === 'string' && !isNaN(Date.parse(value))) {
        (acc as any)[key] = new Date(value);
      } else {
        (acc as any)[key] = value;
      }

      return acc;
    }, {} as T);
  }
}
