import { APP_INITIALIZER, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { LoggerModule } from 'ngx-logger';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { StaticModule } from './static/static.module';
import { AppService } from '@services/app.service';
import { StoreService } from '@services/store.service';
import { DatePipe, registerLocaleData } from '@angular/common';
import { DefaultInterceptorService } from './core/interceptors/default-interceptor.service';
import { appInitFactory } from '@factories/app-init.factory';
import { environment } from '@env/environment';
import localeES from '@angular/common/locales/es';
import localePT from '@angular/common/locales/pt';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { PrivateModule } from './private/private.module';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?v=' + environment.version);
}

registerLocaleData(localeES);
registerLocaleData(localePT);

@NgModule({
  declarations: [AppComponent],
  imports: [
    // Angular modules
    HttpClientModule,
    // BrowserAnimationsModule,
    BrowserModule,

    // External modules
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    LoggerModule.forRoot({
      level: environment.logLevel,
      serverLogLevel: environment.logLevel,
    }),

    // SVG Module
    AngularSvgIconModule.forRoot(),

    AppRoutingModule,

    // Internal modules
    CoreModule,
    SharedModule,
    StaticModule,
    PrivateModule,
  ],
  providers: [
    {
      provide: LOCALE_ID, // TODO: Crear un servicio para gestionar esto
      useValue: 'es-ES',
    },

    // External modules
    {
      provide: APP_INITIALIZER,
      useFactory: appInitFactory,
      deps: [TranslateService, Injector],
      multi: true,
    },

    // Services
    AppService,
    StoreService,

    // Pipes
    DatePipe,

    // Guards

    // Interceptors
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DefaultInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
