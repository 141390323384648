import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { StorageKey } from '@enums/storage-key.enum';
import { StorageSessionHelper } from '@helpers/storage-session.helper';
import { StorageHelper } from '@helpers/storage.helper';
import { EventBusService } from '@services/event-bus.service';

@Component({
  selector: 'lockbin-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'lockbin-backend-portal';
  currentUrl: string | undefined;

  constructor(
    private router: Router,
    private eventBusService: EventBusService) {

    console.log('Iniciando desde App.component.ts. Mensaje que sólo debería mostrarse en DESARROLLO');

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.urlAfterRedirects;
        console.log(event); // Para depurar, ver el objeto completo de NavigationEnd
      }
    });

    // Proceso cuando se hace logout
    this.eventBusService.on('lockbin.logout', () => {

      StorageHelper.removeItem(StorageKey.USER_ID);
      StorageHelper.removeItem(StorageKey.ROLE);
      StorageHelper.removeItem(StorageKey.USER);

      StorageHelper.removeItem(StorageKey.SETUP_CONFIGURATION_PROFILE);

      StorageSessionHelper.clearSessionStorage();

    });

  }

}
