// Angular modules
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaderResponse, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

// External modules
import { Observable, throwError, catchError, delay, BehaviorSubject, switchMap, filter, take } from 'rxjs';

// Environment
import { environment } from '@env/environment';
import { Router } from '@angular/router';
import { EventBusService } from '@services/event-bus.service';
import { AuthService } from 'src/app/auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class DefaultInterceptorService implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  private excludedUrls: RegExp[] = [
    // Coincide con cualquier URL que empiece con "https://maps.googleapis.com/maps/api/"
    /^https:\/\/maps\.googleapis\.com\/maps\/api\/.*/,

    // Coincide con las rutas de firmware, device, card, en diferentes entornos (local, dev, es), con o sin puerto
    /^https:\/\/api\.lockbin\.(local(:8080)?|dev|es|local)\/api\/backend\/firmware\/\d+\/document\/$/,
    /^https:\/\/api\.lockbin\.(local(:8080)?|dev|es|local)\/api\/backend\/device\/import\//,
    /^https:\/\/api\.lockbin\.(local(:8080)?|dev|es|local)\/api\/backend\/card\/import\//,

    // Coincide con las rutas de autenticación
    /^https:\/\/api\.lockbin\.(local(:8080)?|dev|es|local)\/api\/auth\/signin$/,
  ];

  // TODO: Revisar si esto se puede eliminar
  // excludedUrls = [
  //   'https://maps.googleapis.com/maps/api/',

  //   'https://api.lockbin.local:8080/api/backend/firmware/1/document/',
  //   'https://api.lockbin.local/api/backend/firmware/1/document/',
  //   'https://api.lockbin.dev/api/backend/firmware/1/document/',
  //   'https://api.lockbin.es/api/backend/firmware/1/document/',

  //   'https://api.lockbin.local:8080/api/backend/device/import/',
  //   'https://api.lockbin.local/api/backend/device/import/',
  //   'https://api.lockbin.dev/api/backend/device/import/',
  //   'https://api.lockbin.es/api/backend/device/import/',

  //   'https://api.lockbin.local:8080/api/backend/card/import/',
  //   'https://api.lockbin.local/api/backend/card/import/',
  //   'https://api.lockbin.dev/api/backend/card/import/',
  //   'https://api.lockbin.es/api/backend/card/import/',

  //   // TODO: Revisar si la API de autorización tiene que usar el Interceptor
  //   'https://api.lockbin.local:8080/api/auth/signin',
  //   'https://api.lockbin.local/api/auth/signin',
  //   'https://api.lockbin.dev/api/auth/signin',
  //   'https://api.lockbin.es/api/auth/signin',

  // ];

  constructor(
    private router: Router,
    private eventBusService: EventBusService,
    private authService: AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // console.log('🚀 ~ DefaultInterceptorService con REQ:', req);

    // Excluimos el interceptor si la url comienza por alguna de las indicadas.
    // if (this.excludedUrls.findIndex((excludedUrl: string) => req.url.startsWith(excludedUrl)) > -1) {
    //   return next.handle(req);
    // }
    if (this.excludedUrls.some((excludedPattern: RegExp) => excludedPattern.test(req.url))) {
      return next.handle(req);
    }

    req = this.performRequest(req);

    return next.handle(req)
      .pipe(
        delay( environment.delayFake ),
        catchError((error) =>  this.handleDefaultInterceptorError(error, req, next) )
      )
  }


  /**
   * Clones original request injectint httpOptions only for http requests (excludes others like i18n)
   * @param req: original request
   * @returns a clon of the original request
   */
  private performRequest(req: HttpRequest<any>) {

    if( req.url.indexOf('http')!==-1 ){
      return req.clone( this.getHttpOptions() )
    }

    return req.clone(req);
  }


  /**
   *
   * @returns A Json object with HttpOptions to be injected in all Http Request
   */
  private getHttpOptions() {
    return {
      // observe: 'response', // --> It's no posible inject this property in Interceptors (https://stackoverflow.com/questions/48543244/how-to-set-the-observe-response-option-globally-in-angular-4-3)
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
    };
  }

  private handleDefaultInterceptorError(err: HttpHeaderResponse, req: HttpRequest<any>, next: HttpHandler) {

    console.log('Error en DefaultInterceptorService: ', err);

    if (err instanceof HttpErrorResponse && err.status === 401) {
      return this.handle401Error(req, next);
    }

    return throwError(() => err);
  }

  /**
   * Gestiona los errores 401 para intentar regenerar con el refreshToken
   * @param request
   * @param next
   * @returns
   */
  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        switchMap((token: any) => {
          this.isRefreshing = false;
          // TODO: Debería llegar aquí la información del usuario.
          // console.log(token);
          this.refreshTokenSubject.next(token);
          return next.handle(request);
        }),
        catchError((err) => {
          this.isRefreshing = false;
          this.logout(); // Si falla la renovación, cerrar sesión
          return throwError(() => err);
        })
      );
    } else {
      //  Si una solicitud llega mientras la renovación del token está en curso (isRefreshing es true), esa solicitud se suscribe a refreshTokenSubject
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(() => next.handle(request))
      );
    }
  }

  private logout() {
    this.eventBusService.emit({ name: 'lockbin.logout', value: true });
    this.router.navigate(['/autenticacion/acceso']);
  }
}
