
<div class="nk-body bg-lighter-old npc-general has-sidebar" [class.is-sidebar-compact]="isSidebarCompact">
  <div class="nk-app-root">
    <div class="nk-main">

      <lockbin-layout-sidebar class="nk-sidebar nk-sidebar-fixed is-theme"></lockbin-layout-sidebar>

      <div class="nk-wrap">

        <div class="nk-content-map">

          <router-outlet></router-outlet>

        </div>

      </div>

    </div>
  </div>
  </div>
