
<div class="map-issues__header">
  <div class="map-issues__title">{{ 'MAP:ISSUES' | translate }}</div>
  <button class="map-issues__toggle" (click)="doToggleIssues()" attr.aria-label="{{ 'MAP:SHOW_HIDE_ISSUES' | translate }}">
    <div class="map-issues__counter">{{ issuesTotalCounter() }}</div>
    <svg-icon class="map-issues__toggle__svg svg-enable" src="assets/img/status-map/toggle-enable.svg"></svg-icon>
    <svg-icon class="map-issues__toggle__svg svg-disable" src="assets/img/status-map/toggle-disable.svg"></svg-icon>
  </button>
</div>

<div class="map-issues__items" [class.has-active]="hasAnyIssueActive()">

  <ng-container *ngFor="let issue of issues">

    <div class="map-issues__item" [class.active]="issue.active" (click)="doToggleIssue(issue)" *ngIf="issue.quantity > 0">
      <div class="map-issues__item__counter">{{ issue.quantity }}</div>
      <div class="map-issues__item__icon">
        <svg-icon class="map-issues__item__icon__svg" [src]="'assets/img/status-map/alerts/' + issue.iconName + '.svg'"></svg-icon>
      </div>
      <div class="map-issues__item__label">{{ issue.label }}</div>
      <div class="map-issues__item__info">
        <svg-icon class="map-issues__item__info__svg" src="assets/img/status-map/stats-up.svg"></svg-icon>
      </div>
    </div>

  </ng-container>

</div>

<div class="map-issues__empty" *ngIf="issuesTotalCounter() === 0">
  <span>{{ 'MAP:NO_ISSUES' | translate }}</span>
</div>
