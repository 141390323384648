
<div class="map-issues__header">
  <div class="map-issues__title">{{ 'MAP:RECOMMENDATIONS' | translate }}</div>
  <button class="map-issues__toggle" (click)="doToggleRecommendations()" attr.aria-label="{{ 'MAP:SHOW_HIDE_RECOMMENDATIONS' | translate }}">
    <div class="map-issues__counter">{{ recommendationsTotalCounter() }}</div>
    <svg-icon class="map-issues__toggle__svg svg-enable" src="assets/img/status-map/toggle-enable.svg"></svg-icon>
    <svg-icon class="map-issues__toggle__svg svg-disable" src="assets/img/status-map/toggle-disable.svg"></svg-icon>
  </button>
</div>

<div class="map-issues__items" [class.has-active]="hasAnyRecommendationActive()">

  <ng-container *ngFor="let recommendation of recommendations">

    <div class="map-issues__item" [class.active]="recommendation.active" (click)="doToggleRecommendation(recommendation)" *ngIf="recommendation.quantity > 0">
      <div class="map-issues__item__counter">{{ recommendation.quantity }}</div>
      <div class="map-issues__item__icon">
        <svg-icon class="map-issues__item__icon__svg" [src]="'assets/img/status-map/recommendations/' + recommendation.iconName + '.svg'"></svg-icon>
      </div>
      <div class="map-issues__item__label">{{ recommendation.label }}</div>
      <div class="map-issues__item__info">
        <svg-icon class="map-issues__item__info__svg" src="assets/img/status-map/stats-up.svg"></svg-icon>
      </div>
    </div>

  </ng-container>

</div>

<div class="map-issues__empty" *ngIf="recommendationsTotalCounter() === 0">
  <span>{{ 'MAP:NO_RECOMMENDATIONS' | translate }}</span>
</div>
