// Angular modules
import { ChangeDetectorRef, Component, ComponentRef, EventEmitter, HostBinding, Input, OnDestroy, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { IMapEquipment } from '../../models/map-equipment.interface';
import { EquipmentService } from '@equipments/equipments-mgmt/services/equipment.service';
import { EMPTY, map, Subject, switchMap, takeUntil, tap } from 'rxjs';
// import { Equipment } from '@equipments/equipments-mgmt/models';
import { IEquipmentIssue } from '../../models/equipment-issue.interface';
import { MapEquipment } from '../../models/map-equipment.model';
import { StatusMapHelper } from '../../helpers/status-map.helper';
import { MapEquipmentService } from '../../services/map-equipment.service';
import { DataTypeHelper } from '@helpers/data-type.helper';
import { ModalWrapperComponent } from '@modals/modal-wrapper/modal-wrapper.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import ChangeLatLngComponent from 'src/app/shared/components/maps/change-lat-lng/change-lat-lng.component';
import { ICoords } from '@models/index';
import { Equipment } from '@equipments/equipments-mgmt/models';
import { AuthService } from 'src/app/auth/services/auth.service';

@Component({
  selector: 'lockbin-equipment-info',
  templateUrl: './equipment-info.component.html',
  // styleUrls: ['./equipment-info.component.scss'],
})
export class EquipmentInfoComponent implements OnInit, OnDestroy {
  @HostBinding('class') componentCssClass = 'equipment-info';

  @Input() showEquipmentExtendInfo: boolean = false;

  @Output() closeEvent = new EventEmitter<void>();
  // Eventos para solicitar la apertura o cierre del componente de Información Extendida
  @Output() showExtendedInfoEvent = new EventEmitter<MapEquipment>();
  @Output() hideExtendedInfoEvent = new EventEmitter<void>();
  @Output() changeEquipmentLatLng = new EventEmitter<MapEquipment>();

  equipment: MapEquipment | undefined;
  backgroundUrl = '';

  issues!: IEquipmentIssue;

  canEditPosition = false;

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    protected logger: NGXLogger,
    private modalService: NgbModal,
    private equipmentService: EquipmentService,
    private mapEquipmentService: MapEquipmentService,
    private changeDetectorRef: ChangeDetectorRef,
    private authService: AuthService) {}

  ngOnInit(): void {
    this.logger.debug('EquipmentInfoComponent:ngOnInit');

    if (![this.authService.roles.ROLE_CUSTOMER, this.authService.roles.ROLE_PUBLIC, this.authService.roles.ROLE_SUBCONTRACT].includes(this.authService.getRole())) {
      this.canEditPosition = true;
    }

  }

  openInfoEquipment(equipmentMap: IMapEquipment) {
    this.logger.debug('openInfoEquipment', 'Cargamos toda la información del Equipo:', equipmentMap.id);

    this.mapEquipmentService
      .findById(equipmentMap.id)
      .pipe(
        takeUntil(this.unsubscribe$),
        switchMap((equipment: Equipment) => {
          if (equipment) {
            const equipmentData = new MapEquipment(equipment);
            return this.mapEquipmentService
              .findEquipmentStatistics(equipmentMap.id)
              .pipe(
                map((statistics) => {

                  // statistics.accessCountAt = new Date(statistics.accessCountAt);

                  equipmentData.statistics = DataTypeHelper.convertDates(statistics);

                  return equipmentData;
                })
              );
          } else {
            // Si no se encuentra el equipo, lanzamos un observable vacío.
            return EMPTY;
          }
        }),
        tap((equipmentData) => {
          if (equipmentData) {
            this.equipment = equipmentData;
            this.equipment.issues = equipmentMap.issues;
            this.equipment.recommendations = equipmentMap.recommendations;
            this.backgroundUrl = `url("${StatusMapHelper.getImageCardUrl(this.equipment)}")`;
            this.logger.debug('openInfoEquipment', this.equipment);

            // Establecer datos de estadísticas dummy
            // this.setDummyData();
          }
        })
      )
      .subscribe(() => {
        // Forzamos la detección de cambios.
        this.changeDetectorRef.detectChanges();
      });
  }

  private setDummyData() {
    if (this.equipment && this.equipment.statistics) {

      // this.equipment.accesory1 = '';
      // this.equipment.accesory2 = 'Maneta';
      // this.equipment.accesory3 = 'Pedal';
      // this.equipment.accesory4 = '';

      // this.equipment.description5 = 'Av/ As Corbaceiras 54, 36003';

      // this.equipment.device.modeStreetAt = new Date('2024-09-03T09:26:59.000+00:00');

      this.equipment.statistics.connection2gCountAt = new Date('2024-09-01T09:26:59.000+00:00');
      this.equipment.statistics.connectionNbiotCountAt = new Date('2024-09-03T09:26:59.000+00:00');

      this.equipment.statistics.accessCountDay1 = 12;
      this.equipment.statistics.accessCountDay2 = 19;
      this.equipment.statistics.accessCountDay3 = 11;
      this.equipment.statistics.accessCountDay4 = 23;
      this.equipment.statistics.accessCountDay5 = 7;
      this.equipment.statistics.accessCountDay6 = 9;
      this.equipment.statistics.accessCountDay7 = 12;

      this.equipment.statistics.accessCountInterval1 = 12;
      this.equipment.statistics.accessCountInterval2 = 19;
      this.equipment.statistics.accessCountInterval3 = 17;
      this.equipment.statistics.accessCountInterval4 = 24;
      this.equipment.statistics.accessCountInterval5 = 52;
      this.equipment.statistics.accessCountInterval6 = 20;

      this.equipment.statistics.connection2gCountDay1 = 2;
      this.equipment.statistics.connection2gCountDay2 = 4;
      this.equipment.statistics.connection2gCountDay3 = 3;
      this.equipment.statistics.connection2gCountDay4 = 6;
      this.equipment.statistics.connection2gCountDay5 = 7;
      this.equipment.statistics.connection2gCountDay6 = 1;
      this.equipment.statistics.connection2gCountDay7 = 2;
      this.equipment.statistics.connectionKoCountDay1 = 0;
      this.equipment.statistics.connectionKoCountDay2 = 2;
      this.equipment.statistics.connectionKoCountDay3 = 0;
      this.equipment.statistics.connectionKoCountDay4 = 0;
      this.equipment.statistics.connectionKoCountDay5 = 2;
      this.equipment.statistics.connectionKoCountDay6 = 1;
      this.equipment.statistics.connectionKoCountDay7 = 0;
      this.equipment.statistics.connectionNbiotCountDay1 = 8;
      this.equipment.statistics.connectionNbiotCountDay2 = 8;
      this.equipment.statistics.connectionNbiotCountDay3 = 4;
      this.equipment.statistics.connectionNbiotCountDay4 = 9;
      this.equipment.statistics.connectionNbiotCountDay5 = 2;
      this.equipment.statistics.connectionNbiotCountDay6 = 9;
      this.equipment.statistics.connectionNbiotCountDay7 = 6;

      this.equipment.statistics.quality2gCountDay1 = -65;
      this.equipment.statistics.quality2gCountDay2 = -102;
      this.equipment.statistics.quality2gCountDay3 = -102;
      this.equipment.statistics.quality2gCountDay4 = -102;
      this.equipment.statistics.quality2gCountDay5 = -102;
      this.equipment.statistics.quality2gCountDay6 = -102;
      this.equipment.statistics.quality2gCountDay7 = -102;
      this.equipment.statistics.qualityNbiotCountDay1 = -65;
      this.equipment.statistics.qualityNbiotCountDay2 = -54;
      this.equipment.statistics.qualityNbiotCountDay3 = -52;
      this.equipment.statistics.qualityNbiotCountDay4 = -82;
      this.equipment.statistics.qualityNbiotCountDay5 = -71;
      this.equipment.statistics.qualityNbiotCountDay6 = -62;
      this.equipment.statistics.qualityNbiotCountDay7 = -55;

    }
  }

  closeInfo() {
    this.equipment = undefined;

    this.closeEvent.emit();
    this.hideExtendedInfoEvent.emit();
  }

  doClickClose() {
    this.closeInfo();
  }

  doClickShowExtendedInfo() {
    this.showExtendedInfoEvent.emit(this.equipment);
  }

  doClickHideExtendedInfo() {
    this.hideExtendedInfoEvent.emit();
  }

  doClickOnEditLatLng() {

    if (this.equipment) {

      const modalRef = this.modalService.open(ModalWrapperComponent, {
        backdrop: true,
        keyboard: true,
        size: 'md',
        modalDialogClass: '',
        windowClass: '',
      });

      console.log(modalRef);

      const coords: ICoords = {
        latitude: this.equipment?.latitude,
        longitude: this.equipment?.longitude,
      };

      modalRef.componentInstance.component = ChangeLatLngComponent;
      modalRef.componentInstance.componentData = coords;
      modalRef.componentInstance.modalData = {
        title: 'Cambiar coordenadas',
        useHeader: true,
        headerClasses: '',
        closable: true,
      };

      console.log(modalRef.componentInstance);

      modalRef.closed.subscribe((newCoords) => {

        console.log('closed');

        if (newCoords && this.equipment) {
          this.saveCoordsEquipment(this.equipment.id, newCoords);
        }
      });
    }

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  calculateLastConnection() {
    return StatusMapHelper.calculateLastConnection(this.equipment?.statistics);
  }

  calculateAccesories() {
    const accesories = [];

    if (this.equipment) {
      if (this.equipment.accesory1 && this.equipment.accesory1.length > 0) {
        accesories.push(this.equipment.accesory1);
      }
      if (this.equipment.accesory2 && this.equipment.accesory2.length > 0) {
        accesories.push(this.equipment.accesory2);
      }
      if (this.equipment.accesory3 && this.equipment.accesory3.length > 0) {
        accesories.push(this.equipment.accesory3);
      }
      if (this.equipment.accesory4 && this.equipment.accesory4.length > 0) {
        accesories.push(this.equipment.accesory4);
      }
      if (this.equipment.accesory5 && this.equipment.accesory5.length > 0) {
        accesories.push(this.equipment.accesory5);
      }
    }

    return accesories.length > 0 ? accesories.join(', ') : '-';
  }

  calculateActiveDays() {
    if (this.equipment && this.equipment.device.modeStreetAt) {

      const currentDate = new Date();
      const diffInMilliseconds = currentDate.getTime() - this.equipment.device.modeStreetAt.getTime();
      const diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24);

      return Math.round(diffInDays);
    }

    return '-';
  }

  private saveCoordsEquipment(idEquipment: number, coords: ICoords) {

    this.equipmentService.updateCoords(idEquipment, coords).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe({
      next: (equipment: Equipment) => {

        if (this.equipment) {
          this.equipment.description5 = '';
          this.equipment.latitude = equipment.latitude;
          this.equipment.longitude = equipment.longitude;
        }

        this.changeEquipmentLatLng.emit(this.equipment);

      },
      error: (err) => {
        console.error(err);
      }
    });

  }

}
