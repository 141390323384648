import { Component, OnInit } from '@angular/core';
import { BUS_EVENT_TYPE } from '@enums/bus-event-type.enum';
import { StorageKey } from '@enums/storage-key.enum';
import { StorageHelper } from '@helpers/storage.helper';
import { IUserInterfacePrefs } from '@models/user-interface-prefs.interface';
import { EventBusService } from '@services/event-bus.service';

@Component({
  selector: 'lockbin-status-map',
  templateUrl: './status-map.component.html',
  styles: [
  ]
})
export class StatusMapComponent implements OnInit {

  isSidebarCompact!: boolean;

  constructor(
    private eventBusService: EventBusService,

  ) {

    const userPreferences: IUserInterfacePrefs = StorageHelper.getItem(StorageKey.USER_INTERFACE_PREFS);
    this.isSidebarCompact = (userPreferences) ? userPreferences.isSidebarCompact : true;

  }

  public ngOnInit(): void {

    this.eventBusService.on(BUS_EVENT_TYPE.SIDEBAR_TOGGLE_COMPACT, (isSidebarCompact: boolean) =>{
      this.isSidebarCompact = isSidebarCompact;
    });

  }
}
