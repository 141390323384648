// Angular modules
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { forkJoin, Subject, takeUntil, tap } from 'rxjs';
import { MapEquipment } from '../../models/map-equipment.model';
import { ArcElement, BarController, BarElement, CategoryScale, Chart, ChartConfiguration, ChartData, ChartType, DoughnutController, Legend, LinearScale, LineController, LineElement, PointElement, Title, Tooltip } from 'chart.js';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { StatusMapHelper } from '../../helpers/status-map.helper';
import { STATS_CONNECTION_TYPE } from '../../enums/connection-type.enum';
import { IFleetStatistics } from '../../models/fleet-statistics.interface';
import { DeviceService } from '@equipments/devices-mgmt/services/device.service';
import { Reception } from '@mqtt/receptions/models';
import { getReceptionTypeByValue, getReceptionTypes } from '@enums/reception-type.enum';
import { OperationTypeService } from '@configuration/operation-types/services/operation-type.service';
import { OperationType } from '@configuration/operation-types/models';

@Component({
  selector: 'lockbin-equipment-info-comms',
  templateUrl: './equipment-info-comms.component.html',
  // styleUrls: ['./equipment-info-comms.component.scss'],
})
export class EquipmentInfoCommsComponent implements OnInit, AfterViewInit, OnDestroy {

  @HostBinding('class') componentCssClass = 'equipment-info-comms';

  @Input() equipment: MapEquipment | undefined;
  @Input() fleetStatistics!: IFleetStatistics;

  @ViewChild('chartQualityConnectionLayerRef') chartQualityConnectionLayer!: ElementRef;
  @ViewChild('chartConnectionsTypeDeviceLayerRef') chartConnectionsTypeDeviceLayer!: ElementRef;
  @ViewChild('chartConnectionsTypeFleetLayerRef') chartConnectionsTypeFleetLayer!: ElementRef;

  today!: Date;
  communications!: Array<Reception>;
  itemsPerPage = 10;
  currentPage = 1;
  totalItems = 0;

  connectionsTypePercentage = [0, 0, 0];
  fleetConnectionsTypePercentage = [0, 0, 0];
  qualityConnectionsPercentage = [0, 0];
  fleetQualityConnectionsPercentage = [0, 0];

  chartQualityConnection: any;
  chartConnectionsTypeDevice: any;
  chartConnectionsTypeFleet: any;

  connectionLastAt!: Date;
  connectionLastType!: string; // NB-ioT | 2G
  connectionMedia: number = 98;

  receptionTypes: any = getReceptionTypes();
  operationTypes!: OperationType[];

  isRefreshing = false;

  private chartDoughnutOptions: ChartConfiguration<'doughnut'>['options'] = {
    responsive: true,
    aspectRatio: 1,
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      }
    },
    hover: {
      mode: undefined,
    },
    cutout: '40%',
  };

  private qualityBarChartOptions: ChartConfiguration<'bar'>['options'] = {
    indexAxis: 'y', // Esto hace que las barras sean horizontales
    elements: {
      bar: {
        borderWidth: 1,
      }
    },
    aspectRatio: 2.8,
    responsive: true,
    scales: {
      x: {
        position: 'top', // Mueve las etiquetas del eje X a la parte superior
        grid: {
          display: true, // Mostrar líneas de la cuadrícula
          color: 'rgba(0, 0, 0, 0.3)',
        },
        border: {
          display: false,
          dash: [4, 4],
        },
        ticks: {
          font: {
            family: '"Manrope", Arial, sans-serif',
            size: 10,
            lineHeight: 1,
          },
          color: '#8e8e8e',
          stepSize: 25,
          callback: function(value, index, ticks) {
            // Aquí defines cómo quieres transformar los valores numéricos en textos
            const labels = ['Sin señal', '', 'Buena', '', 'Excelente'];
            return labels[index]; // Mapea el valor numérico al texto correspondiente
          }
        },
      },
      y: {
        grid: {
          display: true,
          color: 'rgba(0, 0, 0, 0.05)',
        },
        border: {
          display: false,
        },
        ticks: {
          font: {
            family: '"Manrope", Arial, sans-serif',
            size: 12,
            lineHeight: 1,
          },
          color: '#333',
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      datalabels: {
        display: false,
      }
    }
  };

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    protected logger: NGXLogger,
    private deviceService: DeviceService,
    private changeDetectorRef: ChangeDetectorRef,
    private operationTypeService: OperationTypeService) {

      this.today = new Date();

      Chart.register(LineController, LineElement, PointElement, BarElement, BarController, CategoryScale, LinearScale, BarElement, DoughnutController, ArcElement, Title, Tooltip, Legend, DataLabelsPlugin);

  }

  ngOnInit(): void {
    this.logger.debug('EquipmentInfoCommsComponent:ngOnInit');

    // Obtenemos los datos
    this.initCommunications();

  }

  ngAfterViewInit() {

    this.logger.debug('EquipmentInfoCommsComponent:ngAfterViewInit');

    this.calculateStats();

    this.calculateFleetStats();

    this.createChartQualityConnection();
    this.createChartConnectionsTypeDevice();
    this.createChartConnectionsTypeFleet();

  }

  getReceptionTypeByValue(receptionType: number) {
    return getReceptionTypeByValue(receptionType, this.receptionTypes);
  }

  getOperationTypeById(operationId: number) {

    const operationType = this.operationTypes.find((operation: OperationType) => operation.id === operationId);
    return operationType ? operationType.name : '-';

  }

  doClickRefreshCommunications() {
    this.refreshReception();
  }

  doChangePagination(page: number) {

    this.currentPage = page;

    this.loadCommunications();

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private getOperationTypes() {

    this.operationTypeService.find({}).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((types) => {

      console.log(types);

      this.operationTypes = types.items;

    });
  }

  private calculateStats() {

    if (this.equipment && this.equipment.statistics) {

      // Calcular comunicaciones

      this.connectionLastType = StatusMapHelper.calculateLastConnection(this.equipment.statistics);
      if (this.connectionLastType === STATS_CONNECTION_TYPE.TYPE_2G) {
        this.connectionLastAt = this.equipment.statistics.connection2gCountAt;
      }
      if (this.connectionLastType === STATS_CONNECTION_TYPE.TYPE_NBIOT) {
        this.connectionLastAt = this.equipment.statistics.connectionNbiotCountAt;
      }

      // Calcular conexiones por tipo

      const connectionsNbiot = this.equipment.statistics.connectionNbiotCountDay1 +
        this.equipment.statistics.connectionNbiotCountDay2 +
        this.equipment.statistics.connectionNbiotCountDay3 +
        this.equipment.statistics.connectionNbiotCountDay4 +
        this.equipment.statistics.connectionNbiotCountDay5 +
        this.equipment.statistics.connectionNbiotCountDay6 +
        this.equipment.statistics.connectionNbiotCountDay7;

      const connections2g = this.equipment.statistics.connection2gCountDay1 +
        this.equipment.statistics.connection2gCountDay2 +
        this.equipment.statistics.connection2gCountDay3 +
        this.equipment.statistics.connection2gCountDay4 +
        this.equipment.statistics.connection2gCountDay5 +
        this.equipment.statistics.connection2gCountDay6 +
        this.equipment.statistics.connection2gCountDay7;

      const connectionsKo = this.equipment.statistics.connectionKoCountDay1 +
        this.equipment.statistics.connectionKoCountDay2 +
        this.equipment.statistics.connectionKoCountDay3 +
        this.equipment.statistics.connectionKoCountDay4 +
        this.equipment.statistics.connectionKoCountDay5 +
        this.equipment.statistics.connectionKoCountDay6 +
        this.equipment.statistics.connectionKoCountDay7;

      const total = connections2g + connectionsNbiot + connectionsKo;

      if (total > 0) {
        this.connectionsTypePercentage[0] = Math.round((connectionsNbiot / total) * 100);
        this.connectionsTypePercentage[1] = Math.round((connections2g / total) * 100);
        this.connectionsTypePercentage[2] = Math.round((connectionsKo / total) * 100);
      }

      // Media diaria
      this.connectionMedia = Math.round((connections2g + connectionsNbiot) / 7);


      // Calcular calidad de las conexiones

      const qualityConnectionsNgbiot = [];

      qualityConnectionsNgbiot.push(this.calculateSignalQualityNgbiot(this.equipment.statistics.qualityNbiotCountDay1));
      qualityConnectionsNgbiot.push(this.calculateSignalQualityNgbiot(this.equipment.statistics.qualityNbiotCountDay2));
      qualityConnectionsNgbiot.push(this.calculateSignalQualityNgbiot(this.equipment.statistics.qualityNbiotCountDay3));
      qualityConnectionsNgbiot.push(this.calculateSignalQualityNgbiot(this.equipment.statistics.qualityNbiotCountDay4));
      qualityConnectionsNgbiot.push(this.calculateSignalQualityNgbiot(this.equipment.statistics.qualityNbiotCountDay5));
      qualityConnectionsNgbiot.push(this.calculateSignalQualityNgbiot(this.equipment.statistics.qualityNbiotCountDay6));
      qualityConnectionsNgbiot.push(this.calculateSignalQualityNgbiot(this.equipment.statistics.qualityNbiotCountDay7));

      const mediaNgbiot = this.calculateMedia(qualityConnectionsNgbiot);

      const qualityConnections2g = [];

      qualityConnections2g.push(this.calculateSignalQuality2g(this.equipment.statistics.quality2gCountDay1));
      qualityConnections2g.push(this.calculateSignalQuality2g(this.equipment.statistics.quality2gCountDay2));
      qualityConnections2g.push(this.calculateSignalQuality2g(this.equipment.statistics.quality2gCountDay3));
      qualityConnections2g.push(this.calculateSignalQuality2g(this.equipment.statistics.quality2gCountDay4));
      qualityConnections2g.push(this.calculateSignalQuality2g(this.equipment.statistics.quality2gCountDay5));
      qualityConnections2g.push(this.calculateSignalQuality2g(this.equipment.statistics.quality2gCountDay6));
      qualityConnections2g.push(this.calculateSignalQuality2g(this.equipment.statistics.quality2gCountDay7));

      const media2g = this.calculateMedia(qualityConnections2g);

      this.qualityConnectionsPercentage[0] = mediaNgbiot;
      this.qualityConnectionsPercentage[1] = media2g;

    }
  }

  private createChartQualityConnection() {

    const qualityChartData: ChartData<'bar'> = {
      labels: ['NBIoT', '2G'], // Etiquetas del eje Y
      datasets: [
        {
          label: 'Dispositivo',
          data: this.qualityConnectionsPercentage, // Datos para 'Dispositivo'
          backgroundColor: '#8bc34a', // Color verde
          borderRadius: 6, // Redondeo de las esquinas
          barThickness: 8,
        },
        {
          label: 'Parque',
          data: this.fleetQualityConnectionsPercentage, // Datos para 'Parque'
          backgroundColor: '#9e9e9e', // Color gris
          borderRadius: 6, // Redondeo de las esquinas
          barThickness: 8,
        },
      ]
    };

    this.chartQualityConnection = new Chart(this.chartQualityConnectionLayer.nativeElement, {
      type: 'bar',
      data: qualityChartData,
      options: this.qualityBarChartOptions,
    });

  }

  private createChartConnectionsTypeDevice() {

    const doughnutChartData: ChartData<'doughnut'> = {
      datasets: [{
        data: this.connectionsTypePercentage,
        backgroundColor: [
          '#55802E',
          '#76BC21',
          '#B2B2B2',
        ],
      }]
    };

    this.chartConnectionsTypeDevice = new Chart(this.chartConnectionsTypeDeviceLayer.nativeElement, {
      type: 'doughnut',
      data: doughnutChartData,
      options: this.chartDoughnutOptions,
    });

  }

  private createChartConnectionsTypeFleet() {

    const doughnutChartData: ChartData<'doughnut'> = {
      datasets: [{
        data: this.fleetConnectionsTypePercentage,
        backgroundColor: [
          '#55802E',
          '#76BC21',
          '#B2B2B2',
        ],
      }]
    };

    this.chartConnectionsTypeDevice = new Chart(this.chartConnectionsTypeFleetLayer.nativeElement, {
      type: 'doughnut',
      data: doughnutChartData,
      options: this.chartDoughnutOptions,
    });

  }

  private calculateSignalQualityNgbiot(value: number): number {
    if (value > -65 && value <= -51) {
      return 100;
    } else if (value > -75 && value <= -65) {
      return 75;
    } else if (value > -85 && value <= -75) {
      return 50;
    } else if (value > -90 && value <= -85) {
      return 25;
    } else if (value >= -113 && value <= -90) {
      return 0;
    } else {
      return 0;
    }
  }

  private calculateSignalQuality2g(value: number): number {
    if (value > -70 && value <= -51) {
      return 100;
    } else if (value > -85 && value <= -70) {
      return 75;
    } else if (value > -100 && value <= -85) {
      return 50;
    } else if (value > -110 && value <= -100) {
      return 25;
    } else if (value >= -113 && value <= -100) {
      return 0;
    } else {
      return 0;
    }
  }

  private calculateMedia(values: number[]): number {
    return values.reduce((acc, value) => acc + value, 0) / values.length;
  }

  private calculateFleetStats() {

    // Calcular conexiones por tipo
    const total = this.fleetStatistics.totalConnection2gCount +
      this.fleetStatistics.totalConnectionNbiotCount +
      this.fleetStatistics.totalConnectionKoCount;

    if (total > 0) {
      this.fleetConnectionsTypePercentage[0] = Math.round((this.fleetStatistics.totalConnectionNbiotCount / total) * 100);
      this.fleetConnectionsTypePercentage[1] = Math.round((this.fleetStatistics.totalConnection2gCount / total) * 100);
      this.fleetConnectionsTypePercentage[2] = Math.round((this.fleetStatistics.totalConnectionKoCount / total) * 100);
    }

    // Calcular calidad de las conexiones

    const mediaNgbiot = this.calculateSignalQualityNgbiot(this.fleetStatistics.totalQualityNbiotCount);
    const media2g = this.calculateSignalQuality2g(this.fleetStatistics.totalQuality2gCount);

    this.fleetQualityConnectionsPercentage[0] = mediaNgbiot;
    this.fleetQualityConnectionsPercentage[1] = media2g;
  }

  private getParamsQuery() {

    return {
      pageNo: this.currentPage > 0 ? this.currentPage - 1 : 0,
      pageSize: this.itemsPerPage,
      sort: 'createdAt,DESC',
    };

  }

  private initCommunications() {

    if (this.equipment) {

      const paramsQuery = this.getParamsQuery();

      const $operationType = this.operationTypeService.find({});
      const $reception = this.deviceService.getDeviceReception(this.equipment.device.id, paramsQuery);

      forkJoin({
        operationType: $operationType,
        reception: $reception}).pipe(
          takeUntil(this.unsubscribe$)
        ).subscribe({
          next: ({ operationType, reception }) => {
            this.operationTypes = operationType.items;
            this.communications = reception.items;
            this.totalItems = reception.totalItems;
          },
          error: (err) => console.error(err),
        });
    }

  }

  private loadCommunications() {

    if (this.equipment) {

      const paramsQuery = this.getParamsQuery();

      this.deviceService.getDeviceReception(this.equipment.device.id, paramsQuery).pipe(
        takeUntil(this.unsubscribe$)
      ).subscribe({
          next: (reception) => {
            this.communications = reception.items;
            this.totalItems = reception.totalItems;
          },
          error: (err) => console.error(err),
        });
    }

  }

  private refreshReception() {

    if (this.equipment && !this.isRefreshing) {

      this.isRefreshing = true;

      this.currentPage = 1;

      const paramsQuery = this.getParamsQuery();

      this.deviceService.getDeviceReception(this.equipment.device.id, paramsQuery).pipe(
        takeUntil(this.unsubscribe$)
      ).subscribe({
        next: (data) => {

          this.communications = [];
          this.communications = data.items;
          this.totalItems = data.totalItems;

          console.log('Comunicaciones', this.communications);

          this.isRefreshing = false;

        },
        error: (err) => {
          this.isRefreshing = false;
          console.error(err)
        }
      });

    }
  }

}
