
<div class="map-sidebar-header__content">

  <div class="map-sidebar-header__header">
    <div class="map-sidebar-header__logo" *ngIf="visibleMode === 1 && !backgroundImage">
      <img width="160" src="assets/img/logo-lockbin.svg" alt="Lock.bin">
    </div>
    <div class="map-sidebar-header__title text-uppercase" *ngIf="visibleMode !== 1">{{ 'MAP:STATUS_MAP' | translate }}</div>
    <!-- <img *ngIf="visibleMode === 1" width="120" src="./assets/img/logo-malaga.png" class="d-block pt-4 ps-2" alt=""> -->
  </div>

  <div class="map-sidebar-header__body" *ngIf="visibleMode !== 1">
    <div class="map-sidebar-header__stats">
      <div class="map-sidebar-header__stats__quantity">{{ counterNumElements }}</div>
      <div class="map-sidebar-header__stats__label">{{ 'MAP:CONTAINER' | translate }}</div>
    </div>

    <div class="map-sidebar-header__stats">
      <div class="map-sidebar-header__stats__quantity">{{ counterPercentage }}%</div>
      <div class="map-sidebar-header__stats__label">{{ 'MAP:WORKING_WELL' | translate }}</div>
    </div>
  </div>

</div>

<img *ngIf="visibleMode !== 1" class="map-sidebar-header__background-image" width="185" src="assets/img/status-map/lockbin-dev.png" alt="">
