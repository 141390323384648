import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, forkJoin, map, mergeMap, of, switchMap } from 'rxjs';
import { IMapEquipment } from '../models/map-equipment.interface';
import { environment } from '@env/environment';
import { IMapZone } from '../models/map-zone.interface';
import { IMapGroup } from '../models/map-group.interface';
import { IMapGroupType } from '../models/map-group-type.interface';
import { Equipment } from '@equipments/equipments-mgmt/models';
import { HttpQueryUtils } from '@services/http-query-utils.service';
import { QueryResults } from '@models/api-request.model';
import { Customer } from '@customers/customers-mgmt/models';
import { Device } from '@equipments/devices-mgmt/models';
import { EquipmentModel } from '@configuration/equipment-models/models';
import { EquipmentCategory } from '@configuration/equipment-categories/models';
import { EquipmentType } from '@configuration/equipment-types/models';
import { Status } from '@configuration/status/models';
import { DeviceService } from '@equipments/devices-mgmt/services/device.service';
import { CustomerService } from '@customers/customers-mgmt/services/customer.service';
import { EquipmentModelService } from '@configuration/equipment-models/services/equipment-model.service';
import { EquipmentTypeService } from '@configuration/equipment-types/services/equipment-type.service';
import { EquipmentCategoryService } from '@configuration/equipment-categories/services/equipment-category.service';
import { StatusService } from '@configuration/status/services/status.service';
import { Firmware } from '@configuration/firmwares/models';
import { Hardware } from '@configuration/hardwares/models';
import { HardwareService } from '@configuration/hardwares/services/hardware.service';
import { FirmwareService } from '@configuration/firmwares/services/firmware.service';
import { IEquipmentStatistics } from '../models/equipment-statistics.interface';
import { IFleetStatistics } from '../models/fleet-statistics.interface';

const BACKEND_API = `${environment.apiBaseUrl}`;

@Injectable({
  providedIn: 'root',
})
export class MapEquipmentService {
  constructor(
    private http: HttpClient,
    public httpQueryUtils: HttpQueryUtils,
    private customerService: CustomerService,
    private equimentModelService: EquipmentModelService,
    private equimentTypeService: EquipmentTypeService,
    private equimentCategoryService: EquipmentCategoryService,
    private statusService: StatusService,
    private hardwareService: HardwareService,
    private firmwareService: FirmwareService,
    private deviceService: DeviceService
  ) {}

  findAll(): Observable<IMapEquipment[]> {
    const url = `${BACKEND_API}/equipment/`;

    return this.http.get<IMapEquipment[]>(url);
  }

  // TODO: Optimizar esto añadiendo el IMEI en el equipo
  /**
   * Obtiene los equipos pero le añade el IMEI del dispositivo
   *
   * @returns IMapEquipment[]
   */
  findAllWithDevice(): Observable<IMapEquipment[]> {

    const url = `${BACKEND_API}/equipment/`;

    return this.http.get<IMapEquipment[]>(url).pipe(
      mergeMap(equipments => {

        if (equipments && equipments.length > 0) {

          // Aquí generamos un array de observables para cada detalle
          const deviceObservables = equipments.map(equipment =>
            this.deviceService.findById(equipment.deviceId).pipe(
              map(device => {

                equipment.IMEI = device.imei;

                return equipment;
              })
            )
          );

          return forkJoin(deviceObservables);
        }

        return of([]);
      })
    );
  }

  findZones(): Observable<IMapZone[]> {
    const url = `${BACKEND_API}/zone/`;

    return this.http.get<IMapZone[]>(url);
  }

  findGroupTypes(): Observable<IMapGroupType[]> {
    const url = `${BACKEND_API}/equipmentGroupType/`;

    return this.http.get<IMapGroupType[]>(url);
  }

  findGroups(): Observable<IMapGroup[]> {
    const url = `${BACKEND_API}/equipmentGroup/`;

    return this.http.get<IMapGroup[]>(url);
  }

  findPostalCodes(): Observable<IMapGroup[]> {
    const url = `${BACKEND_API}/equipmentGroup/filter/`;

    const groupTypePostalCode = {
      equipmentGroupTypeId: 1,
      name: '',
    };

    return this.http.post<IMapGroup[]>(url, groupTypePostalCode);
  }

  findById(id: number): Observable<Equipment> {

    const url = `${BACKEND_API}/equipment/${id}/`;

    return this.httpQueryUtils.executeQuery<Equipment>(url, {}).pipe(
      map((queryResults: QueryResults<Equipment>) => queryResults.items.slice(0, 1)),
      switchMap((items: Equipment[]) => {

        // gets arrays of Observables foreach element in "queryResults.items"
        const requestDetail_1: Observable<Customer>[] = this.getDetailObservablesCustomer(items);
        const requestDetail_2: Observable<Device>[] = this.getDetailObservablesDevice(items);
        const requestDetail_3: Observable<EquipmentModel>[] = this.getDetailObservablesEquipmentModel(items);
        const requestDetail_4: Observable<EquipmentCategory>[] = this.getDetailObservablesEquipmentCategory(items);
        const requestDetail_5: Observable<EquipmentType>[] = this.getDetailObservablesEquipmentType(items);
        const requestDetail_6: Observable<Status>[] = this.getDetailObservablesStatus(items);

        // la caña de España!
        return forkJoin([...requestDetail_1, ...requestDetail_2, ...requestDetail_3, ...requestDetail_4, ...requestDetail_5, ...requestDetail_6]).pipe(
          map((arrayWithDetailData: any) => {
            // each item array is detail data from one Observable.

            const resultDetail_1 = arrayWithDetailData.slice(0, items.length);
            const resultDetail_2 = arrayWithDetailData.slice(items.length, items.length * 2);
            const resultDetail_3 = arrayWithDetailData.slice(items.length * 2);
            const resultDetail_4 = arrayWithDetailData.slice(items.length * 3, items.length * 4);
            const resultDetail_5 = arrayWithDetailData.slice(items.length * 4, items.length * 5);
            const resultDetail_6 = arrayWithDetailData.slice(items.length * 5);

            items.map((item: Equipment, i) => {
              item.customer = resultDetail_1[i];
              item.device = resultDetail_2[i];
              item.equipmentModel = resultDetail_3[i];
              item.equipmentCategory = resultDetail_4[i];
              item.equipmentType = resultDetail_5[i];
              item.status = resultDetail_6[i];

              return item;
            });

            return items[0];
          })
        );
      })
    );
  }

  searchEquipment(searchText: string) {

    const url = `${BACKEND_API}/equipment/`;

    return this.http.get<IMapEquipment[]>(url, {
      params: {
        serial: searchText,
      }
    }).pipe(
      mergeMap(equipments => {

        if (equipments && equipments.length > 0) {
          // Aquí generamos un array de observables para cada detalle
          const deviceObservables = equipments.map(equipment =>
            this.deviceService.findById(equipment.deviceId).pipe(
              map(device => {

                equipment.IMEI = device.imei;

                return equipment;
              })
            )
          );

          return forkJoin(deviceObservables);
        }

        return of([]);
      })
    );

  }

  /**
   *
   * @param items array of items
   * @returns Array of Observables for get detailed data (findById)
   */
  private getDetailObservablesCustomer(items: Equipment[]): Observable<Customer>[] {
    return items.map((item: Equipment) => {
      if (item.customerId && item.customerId > 0) {
        return this.customerService.findById(item.customerId);
      } else {
        return of(new Customer());
      }
    });
  }

  /**
   *
   * @param items array of items
   * @returns Array of Observables for get detailed data (findById)
   */
  private getDetailObservablesDevice(items: Equipment[]): Observable<Device>[] {
    return items.map((item: Equipment) => {
      if (item.deviceId && item.deviceId > 0) {
        return this.findDeviceById(item.deviceId);
      } else {
        return of(new Device());
      }
    });
  }

  /**
   *
   * @param items array of items
   * @returns Array of Observables for get detailed data (findById)
   */
  private getDetailObservablesEquipmentModel(items: Equipment[]): Observable<EquipmentModel>[] {
    return items.map((item: Equipment) => {
      if (item.equipmentModelId && item.equipmentModelId > 0) {
        return this.equimentModelService.findById(item.equipmentModelId);
      } else {
        return of(new EquipmentModel());
      }
    });
  }

  /**
   *
   * @param items array of items
   * @returns Array of Observables for get detailed data (findById)
   */
  private getDetailObservablesEquipmentCategory(items: Equipment[]): Observable<EquipmentCategory>[] {
    return items.map((item: Equipment) => {
      if (item.equipmentCategoryId && item.equipmentCategoryId > 0) {
        return this.equimentCategoryService.findById(item.equipmentCategoryId);
      } else {
        return of(new EquipmentCategory());
      }
    });
  }

  /**
   *
   * @param items array of items
   * @returns Array of Observables for get detailed data (findById)
   */
  private getDetailObservablesEquipmentType(items: Equipment[]): Observable<EquipmentType>[] {
    return items.map((item: Equipment) => {
      if (item.equipmentTypeId && item.equipmentTypeId > 0) {
        return this.equimentTypeService.findById(item.equipmentTypeId);
      } else {
        return of(new EquipmentType());
      }
    });
  }

  /**
   *
   * @param items array of items
   * @returns Array of Observables for get detailed data (findById)
   */
  private getDetailObservablesStatus(items: Equipment[]): Observable<Status>[] {
    return items.map((item: Equipment) => {
      if (item.equipmentTypeId && item.equipmentTypeId > 0) {
        return this.statusService.findById(item.statusId || 0);
      } else {
        return of(new Status());
      }
    });
  }

  /**
   *
   * @param items array of items
   * @returns Array of Observables for get detailed data (findById)
   */
  private getDetailObservablesCustomerDevice(items: Device[]): Observable<Customer>[] {

    return items.map((item: Device) => {

      if (item.customerId && item.customerId>0) {
        return this.customerService.findById(item.customerId)
      } else {
        return of(new Customer());
      }

    });

  }

  /**
   *
   * @param items array of items
   * @returns Array of Observables for get detailed data (findById)
   */
  private getDetailObservablesFirmware(items: Device[]): Observable<Firmware>[] {

    return items.map((item: Device) => {

      if (item.firmwareId && item.firmwareId > 0) {
        return this.firmwareService.findById(item.firmwareId)
      } else {
        return of(new Firmware());
      }

    });

  }

  /**
   *
   * @param items array of items
   * @returns Array of Observables for get detailed data (findById)
   */
  private getDetailObservablesHardware(items: Device[]): Observable<Hardware>[] {

    return items.map((item: Device) => {

      if (item.hardwareId && item.hardwareId > 0) {
        return this.hardwareService.findById(item.hardwareId)
      } else {
        return of(new Hardware());
      }

    });

  }

  findDeviceById(id: number): Observable<Device> {

    const url = `${BACKEND_API}/device/${id}/`;
    return this.httpQueryUtils.executeQuery<Device>(url, {})
            .pipe(
              map((queryResults: QueryResults<Device>) => queryResults.items.slice(0,1) ),
              switchMap((items: Device[]) => {

                // gets arrays of Observables foreach element in "queryResults.items"
                const requestDetail_1: Observable<Customer>[] = this.getDetailObservablesCustomerDevice(items);
                const requestDetail_2: Observable<Firmware>[] = this.getDetailObservablesFirmware(items);
                const requestDetail_3: Observable<Hardware>[] = this.getDetailObservablesHardware(items);


                // la caña de España!
                return forkJoin([...requestDetail_1, ...requestDetail_2, ...requestDetail_3])
                  .pipe(
                    map((arrayWithDetailData: any) => { // each item array is detail data from one Observable.

                      const resultDetail_1 = arrayWithDetailData.slice(0, items.length);
                      const resultDetail_2 = arrayWithDetailData.slice(items.length, items.length * 2);
                      const resultDetail_3 = arrayWithDetailData.slice(items.length * 2, items.length * 3);
                      const resultDetail_4 = arrayWithDetailData.slice(items.length * 3);

                      items
                        .map((item: Device, i) => {

                          item.customer   = resultDetail_1[i];
                          item.firmware    = resultDetail_2[i];
                          item.hardware   = resultDetail_3[i];
                          item.broker     = resultDetail_4[i];

                          return item;
                        });


                      return items[0];
                    })
                  );


              })
            )
  }


  findEquipmentStatistics(equipmentId: number): Observable<IEquipmentStatistics> {
    return this.http.get<IEquipmentStatistics>(`${BACKEND_API}/equipment/${equipmentId}/statistics`);
  }

  findAllStatistics(): Observable<IFleetStatistics> {
    return this.http.get<IFleetStatistics>(`${BACKEND_API}/statistics`);
  }

}
