
<!-- <button class="equipment-card__info__open" aria-label="Abrir equipo" (click)="doClickMoreInfo()" [class.invisible]="showEquipmentExtendInfo">
  <img src="./assets/img/status-map/icon-open.png" width="43" height="115" class="img-fluid"/>
</button> -->

<div class="equipment-card">

  <button class="equipment-card__close" (click)="doClickClose()" attr.aria-label="{{ 'BTN_CLOSE' | translate }}">
    <svg-icon class="equipment-card__close__icon" src="assets/img/status-map/close.svg"></svg-icon>
  </button>

  <div class="equipment-card__content" *ngIf="equipment">

    <div class="equipment-card__header">

      <div class="equipment-card__equipment">

        <div class="equipment-card__equipment__photo" [style.background-image]="backgroundUrl">
          <img width="250" src="./assets/img/status-map/contenedor-grande.png" alt="">
        </div>
        <div class="equipment-card__equipment__data">

          <div class="equipment-card__equipment__title" title="{{ equipment.id }}">
            {{ equipment.equipmentModel.name | titlecase }}
            <sup class="sr-only">{{ equipment.id }}</sup>
          </div>
          <div class="equipment-card__equipment__title">{{ equipment.equipmentCategory.name | titlecase }}</div>

          <div class="equipment-card__equipment__statuses">
            <div class="equipment-card__equipment__status">
              <svg-icon class="equipment-card__equipment__status__icon" [src]="'assets/img/status-map/icon-status-battery.svg'"></svg-icon>
              <span class="equipment-card__equipment__status__label">-</span>
              <!--
              <span class="equipment-card__equipment__status__label" *ngIf="!equipment.alertNoBattery">Alta</span>
              <span class="equipment-card__equipment__status__label" *ngIf="!equipment.alertNoBattery">Media</span>
              <span class="equipment-card__equipment__status__label" *ngIf="equipment.alertNoBattery">Baja</span>
              -->
            </div>
            <div class="equipment-card__equipment__status">
              <svg-icon class="equipment-card__equipment__status__icon" [src]="'assets/img/status-map/icon-status-connectivity.svg'"></svg-icon>
              <span class="equipment-card__equipment__status__label">{{ calculateLastConnection() }}</span>
            </div>
            <div class="equipment-card__equipment__status">
              <svg-icon class="equipment-card__equipment__status__icon" [src]="'assets/img/status-map/icon-status-availability.svg'"></svg-icon>
              <span class="equipment-card__equipment__status__label">{{ equipment.statistics?.accessCount }}/{{ 'MAP:DAY' | translate }}</span>
            </div>
          </div>

        </div>
      </div>

    </div>

    <ngx-simplebar class="equipment-card__body" [options]="{ autoHide: true }">

      <div class="pt-4 mt-2" *ngIf="equipment.issues && equipment.issues.length > 0 || equipment.recommendations && equipment.recommendations.length > 0">

        <div class="equipment-card__issues" *ngIf="equipment.issues && equipment.issues.length > 0">
          <div class="equipment-card__issue" *ngFor="let issue of equipment.issues">
            <div class="equipment-card__issue__icon">
              <svg-icon class="equipment-card__issue__icon__svg" [src]="'assets/img/status-map/alerts/alert-' + issue.key + '.svg'"></svg-icon>
            </div>
            <div class="equipment-card__issue__content">
              <div class="equipment-card__issue__label">{{ issue.label }}</div>
              <div class="equipment-card__issue__date" *ngIf="issue.date">{{ 'MAP:REGISTER_DATE' | translate }}: <strong>{{ issue.date | date: 'dd/MM/yyyy' }}</strong></div>
            </div>
          </div>
        </div>

        <div class="equipment-card__recommendations" *ngIf="equipment.recommendations && equipment.recommendations.length > 0">
          <div class="equipment-card__recommendation" *ngFor="let recommendation of equipment.recommendations">
            <div class="equipment-card__recommendation__icon">
              <svg-icon class="equipment-card__recommendation__icon__svg" [src]="'assets/img/status-map/recommendations/recommendation-' + recommendation.key + '.svg'"></svg-icon>
            </div>
            <div class="equipment-card__recommendation__content">
              <div class="equipment-card__recommendation__label">{{ recommendation.label }}</div>
              <div class="equipment-card__recommendation__date" *ngIf="recommendation.date">{{ 'MAP:REGISTER_DATE' | translate }}: <strong>{{ recommendation.date | date: 'dd/MM/yyyy' }}</strong></div>
            </div>
          </div>
        </div>

      </div>

      <div class="equipment-card__info">
        <div class="equipment-card__info__title">{{ 'MAP:INFO' | translate }}</div>

        <!-- <div class="equipment-card__info__item" *ngIf="equipment.equipmentType">Carga: <strong>{{ equipment.equipmentType.name | titlecase }}</strong></div> -->
        <div class="equipment-card__info__item">{{ 'MAP:SERIAL_CONTAINER' | translate }}: <strong>{{ equipment.serial }}</strong></div>
        <div class="equipment-card__info__item">{{ 'MAP:LOAD' | translate }}: <strong>{{ equipment.description1 }}</strong></div>
        <div class="equipment-card__info__item">{{ 'MAP:CLOSE_IN' | translate }}: <strong>{{ equipment.description2 }}</strong></div>

        <div class="pt-2">
          <div class="equipment-card__info__item">{{ 'MAP:CLOSE_VERSION' | translate }}: <strong>{{ equipment.description3 }}</strong></div>
          <div class="equipment-card__info__item" *ngIf="equipment.device">IMEI: <strong>{{ equipment.device.imei }}</strong></div>
          <div class="equipment-card__info__item" *ngIf="equipment.device">SIM: <strong>{{ equipment.device.iccid }}</strong></div>
        </div>

        <div class="pt-2" *ngIf="equipment.device.firmware || equipment.device.hardware">
          <div class="equipment-card__info__item" *ngIf="equipment.device.hardware">Hardware: <strong>{{ equipment.device.hardware.id > 0 ? equipment.device.hardware.name : '-' }}</strong></div>
          <div class="equipment-card__info__item" *ngIf="equipment.device.firmware">Firmware: <strong>{{ equipment.device.firmware.id > 0 ? equipment.device.firmware.name : '-' }}</strong></div>
        </div>

        <div class="pt-2">
          <div class="equipment-card__info__item" *ngIf="equipment.device.firmware">{{ 'MAP:ACCESORY' | translate }}:
            <strong>{{ calculateAccesories() }}</strong>
          </div>
        </div>

        <div class="equipment-card__info__item pt-2">{{ 'MAP:ACTIVE' | translate }}:
          <div class="d-inline" *ngIf="equipment.device.modeStreetAt">
            <strong>{{ calculateActiveDays() }} {{ 'MAP:DAY' | translate }} - <span>{{ equipment.device.modeStreetAt | date: 'dd/MM/yyyy' }}</span></strong>
          </div>
          <div class="d-inline" *ngIf="!equipment.device.modeStreetAt">
            -
          </div>
        </div>

        <div class="equipment-card__info__item pt-2 d-flex align-items-start">
          <button class="btn p-0 flex-shrink-0" (click)="doClickOnEditLatLng()" aria-label="Editar ubicación" *ngIf="canEditPosition">
            <svg-icon class="pe-1" [svgStyle]="{ 'width.px': 14, 'opacity': '0.7' }" src="assets/img/status-map/edit-pencil.svg"></svg-icon>
          </button>
          <div class="">
            {{ 'MAP:LOCATION' | translate }}: <strong>{{ equipment.latitude }}, {{ equipment.longitude }}</strong>
            <div class="pt-1 text-gray" *ngIf="equipment.description5">({{ equipment.description5 }})</div>
          </div>
        </div>

        <div class="equipment-card__info__actions">
          <button class="equipment-card__info__button" (click)="doClickShowExtendedInfo()" *ngIf="!showEquipmentExtendInfo">{{ 'MAP:SHOW_MORE' | translate }}</button>
          <button class="equipment-card__info__button" (click)="doClickHideExtendedInfo()" *ngIf="showEquipmentExtendInfo">{{ 'MAP:SHOW_LESS' | translate }}</button>
        </div>

      </div>

    </ngx-simplebar>

  </div>

</div>
