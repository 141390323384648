// Angular modules
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Subject, takeUntil, tap } from 'rxjs';
import { MapEquipment } from '../../models/map-equipment.model';
import { ArcElement, BarController, BarElement, CategoryScale, Chart, ChartConfiguration, ChartData, ChartType, DoughnutController, Legend, LinearScale, LineController, LineElement, PointElement, Title, Tooltip } from 'chart.js';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { StatusMapHelper } from '../../helpers/status-map.helper';
import { STATS_CONNECTION_TYPE } from '../../enums/connection-type.enum';
import { MapEquipmentService } from '../../services/map-equipment.service';
import { IFleetStatistics } from '../../models/fleet-statistics.interface';

@Component({
  selector: 'lockbin-equipment-info-general',
  templateUrl: './equipment-info-general.component.html',
  // styleUrls: ['./equipment-info-general.component.scss'],
})
export class EquipmentInfoGeneralComponent implements OnInit, AfterViewInit, OnDestroy {
  @HostBinding('class') componentCssClass = 'equipment-info-general';

  @Input() equipment: MapEquipment | undefined;
  @Input() fleetStatistics!: IFleetStatistics;

  @ViewChild('chartUseDailyLayerRef') chartUseDailyLayer!: ElementRef;
  @ViewChild('chartUseHoraryLayerRef') chartUseHoraryLayer!: ElementRef;
  @ViewChild('chartQualityConnectionLayerRef') chartQualityConnectionLayer!: ElementRef;
  @ViewChild('chartConnectionsTypeDeviceLayerRef') chartConnectionsTypeDeviceLayer!: ElementRef;
  @ViewChild('chartConnectionsTypeFleetLayerRef') chartConnectionsTypeFleetLayer!: ElementRef;

  today!: Date;
  // fleetStatistics!: IFleetStatistics;

  connectionsTypePercentage = [0, 0, 0];
  fleetConnectionsTypePercentage = [0, 0, 0];
  qualityConnectionsPercentage = [0, 0];
  fleetQualityConnectionsPercentage = [0, 0];

  chartUseDaily: any;
  chartUseHorary: any;
  chartQualityConnection: any;
  chartConnectionsTypeDevice: any;
  chartConnectionsTypeFleet: any;

  connectionLastAt!: Date;
  connectionLastType!: string; // NB-ioT | 2G
  connectionMedia: number = 98;

  accessCountMedia!: number;
  fleetAccessCountMedia!: number;

  accessMediaUse!: number;
  accessMediaUseText!: string; // Menor Uso | Mayor Uso | Igual Uso

  private chartBarDailyOptions: ChartConfiguration['options'] = {
    responsive: true,
    aspectRatio: 2.8,
    scales: {
      x: {
        type: 'category',
        grid: {
          display: false,
        },
        border: {
          display: true,
          color: '#000',
        },
        ticks: {
          font: {
            family: '"Manrope", Arial, sans-serif',
            size: 10,
            lineHeight: 1,
          },
          color: '#8e8e8e',
        }
      },
      y: {
        type: 'linear',
        beginAtZero: true,
        ticks: {
          display: false,
        },
        border: {
          display: false,
        },
        grid: {
          display: false,
        }
      }
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        bodyAlign: 'center',
        yAlign: 'bottom',
        callbacks: {
          title: () => { return ''; },
        }
      },
      datalabels: {
        anchor: (context) => {
          const dataset = context.dataset.data as number[];
          const value = context.dataset.data[context.dataIndex] as number;
          const maxValue = Math.max(...dataset);
          const minValue = Math.min(...dataset);

          // Cambiar anchor dependiendo de si es el valor más alto o más bajo
          if (value === maxValue) {
            return 'center';
          } else if (value === minValue) {
            return 'end';
          }
          return 'center';
        },
        align: (context) => {
          const dataset = context.dataset.data as number[];
          const value = context.dataset.data[context.dataIndex] as number;
          const maxValue = Math.max(...dataset);
          const minValue = Math.min(...dataset);

          // Cambiar align dependiendo de si es el valor más alto o más bajo
          if (value === maxValue) {
            return 'center';
          } else if (value === minValue) {
            return 'end';
          }
          return 'center'; // Alineación por defecto
        },
        color: (context) => {
          const dataset = context.dataset.data as number[];
          const value = context.dataset.data[context.dataIndex] as number;
          const maxValue = Math.max(...dataset);
          const minValue = Math.min(...dataset);

          // Cambiar color dependiendo de si es el valor más alto o más bajo
          if (value === maxValue) {
            return '#fff';
          } else if (value === minValue) {
            return '#999';
          }
          return '#fff';
        },
        font: {
          size: 10,
        },
        display: (context) => {
          const dataset = context.dataset.data as number[];
          const value = context.dataset.data[context.dataIndex] as number;
          const maxValue = Math.max(...dataset);
          const minValue = Math.min(...dataset);

          // Mostrar solo si el valor es el máximo o el mínimo
          return value === maxValue || value === minValue;
        },
      },
    }
  };

  private chartBarHoraryOptions: ChartConfiguration['options'] = {
    responsive: true,
    aspectRatio: 2.8,
    scales: {
      x: {
        type: 'category',
        grid: {
          display: false,
        },
        border: {
          display: true,
          color: '#000',
        },
        ticks: {
          font: {
            family: '"Manrope", Arial, sans-serif',
            size: 10,
            lineHeight: 1,
          },
          color: '#8e8e8e',
        }
      },
      y: {
        type: 'linear',
        beginAtZero: true,
        ticks: {
          display: false,
        },
        border: {
          display: false,
        },
        grid: {
          display: false,
        }
      }
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        bodyAlign: 'center',
        yAlign: 'bottom',
        callbacks: {
          title: () => { return ''; },
        }
      },
      datalabels: {
        anchor: (context) => {
          const dataset = context.dataset.data as number[];
          const value = context.dataset.data[context.dataIndex] as number;
          const maxValue = Math.max(...dataset);
          const minValue = Math.min(...dataset);

          // Cambiar anchor dependiendo de si es el valor más alto o más bajo
          if (value === maxValue) {
            return 'center';
          } else if (value === minValue) {
            return 'end';
          }
          return 'center';
        },
        align: (context) => {
          const dataset = context.dataset.data as number[];
          const value = context.dataset.data[context.dataIndex] as number;
          const maxValue = Math.max(...dataset);
          const minValue = Math.min(...dataset);

          // Cambiar align dependiendo de si es el valor más alto o más bajo
          if (value === maxValue) {
            return 'center';
          } else if (value === minValue) {
            return 'end';
          }
          return 'center'; // Alineación por defecto
        },
        color: (context) => {
          const dataset = context.dataset.data as number[];
          const value = context.dataset.data[context.dataIndex] as number;
          const maxValue = Math.max(...dataset);
          const minValue = Math.min(...dataset);

          // Cambiar color dependiendo de si es el valor más alto o más bajo
          if (value === maxValue) {
            return '#fff';
          } else if (value === minValue) {
            return '#999';
          }
          return '#fff';
        },
        font: {
          size: 10,
        },
        display: (context) => {
          const dataset = context.dataset.data as number[];
          const value = context.dataset.data[context.dataIndex] as number;
          const maxValue = Math.max(...dataset);
          const minValue = Math.min(...dataset);

          // Mostrar solo si el valor es el máximo o el mínimo
          return value === maxValue || value === minValue;
        },
      },
    }
  };

  private chartDoughnutOptions: ChartConfiguration<'doughnut'>['options'] = {
    responsive: true,
    aspectRatio: 1,
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      }
    },
    hover: {
      mode: undefined,
    },
    cutout: '40%',
  };

  private qualityBarChartOptions: ChartConfiguration<'bar'>['options'] = {
    indexAxis: 'y', // Esto hace que las barras sean horizontales
    elements: {
      bar: {
        borderWidth: 1,
      }
    },
    aspectRatio: 2.8,
    responsive: true,
    scales: {
      x: {
        position: 'top', // Mueve las etiquetas del eje X a la parte superior
        grid: {
          display: true, // Mostrar líneas de la cuadrícula
          color: 'rgba(0, 0, 0, 0.3)',
        },
        border: {
          display: false,
          dash: [4, 4],
        },
        ticks: {
          font: {
            family: '"Manrope", Arial, sans-serif',
            size: 10,
            lineHeight: 1,
          },
          color: '#8e8e8e',
          stepSize: 25,
          callback: function(value, index, ticks) {
            // Aquí defines cómo quieres transformar los valores numéricos en textos
            const labels = ['Sin señal', '', 'Buena', '', 'Excelente'];
            return labels[index]; // Mapea el valor numérico al texto correspondiente
          }
        },
      },
      y: {
        grid: {
          display: true,
          color: 'rgba(0, 0, 0, 0.05)',
        },
        border: {
          display: false,
        },
        ticks: {
          font: {
            family: '"Manrope", Arial, sans-serif',
            size: 12,
            lineHeight: 1,
          },
          color: '#333',
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      datalabels: {
        display: false,
      }
    }
  };

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    protected logger: NGXLogger,
    private mapEquipmentService: MapEquipmentService,
    private changeDetectorRef: ChangeDetectorRef) {

      this.today = new Date();

      Chart.register(LineController, LineElement, PointElement, BarElement, BarController, CategoryScale, LinearScale, BarElement, DoughnutController, ArcElement, Title, Tooltip, Legend, DataLabelsPlugin);

  }

  ngOnInit(): void {
    this.logger.debug('EquipmentInfoGeneralComponent:ngOnInit');
  }

  ngAfterViewInit() {

    this.logger.debug('EquipmentInfoGeneralComponent:ngAfterViewInit');

    this.calculateStats();

    this.calculateFleetStats();

    this.createChartUseDaily();
    this.createChartUseHorary();
    this.createChartQualityConnection();
    this.createChartConnectionsTypeDevice();
    this.createChartConnectionsTypeFleet();

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private loadFleetStatistics() {
    this.mapEquipmentService.findAllStatistics().pipe(
      takeUntil(this.unsubscribe$),
      // tap((fleetStatistics) => {

      //   this.setDummyData(fleetStatistics);

      // })
      ).subscribe((stats) => {
        if (stats) {
          this.fleetStatistics = stats;
          this.calculateFleetStats();
        }
      }
    );
  }

  private setDummyData(fleetStatistics: IFleetStatistics) {

    fleetStatistics.totalConnection2gCount = 2102;
    fleetStatistics.totalConnectionNbiotCount = 2490;
    fleetStatistics.totalConnectionKoCount = 325;


    fleetStatistics.totalQualityNbiotCount = -80;
    fleetStatistics.totalQuality2gCount = -102;

    fleetStatistics.totalAccessCount = 11;

  }

  private calculateStats() {

    if (this.equipment && this.equipment.statistics) {

      // Accesos (Aperturas?)
      this.accessCountMedia = Math.round((this.equipment.statistics.accessCountDay1 +
        this.equipment.statistics.accessCountDay2 +
        this.equipment.statistics.accessCountDay3 +
        this.equipment.statistics.accessCountDay4 +
        this.equipment.statistics.accessCountDay5 +
        this.equipment.statistics.accessCountDay6 +
        this.equipment.statistics.accessCountDay7) / 7);

      // Calcular comunicaciones

      this.connectionLastType = StatusMapHelper.calculateLastConnection(this.equipment.statistics);
      if (this.connectionLastType === STATS_CONNECTION_TYPE.TYPE_2G) {
        this.connectionLastAt = this.equipment.statistics.connection2gCountAt;
      }
      if (this.connectionLastType === STATS_CONNECTION_TYPE.TYPE_NBIOT) {
        this.connectionLastAt = this.equipment.statistics.connectionNbiotCountAt;
      }

      // Calcular conexiones por tipo

      const connectionsNbiot = this.equipment.statistics.connectionNbiotCountDay1 +
        this.equipment.statistics.connectionNbiotCountDay2 +
        this.equipment.statistics.connectionNbiotCountDay3 +
        this.equipment.statistics.connectionNbiotCountDay4 +
        this.equipment.statistics.connectionNbiotCountDay5 +
        this.equipment.statistics.connectionNbiotCountDay6 +
        this.equipment.statistics.connectionNbiotCountDay7;

      const connections2g = this.equipment.statistics.connection2gCountDay1 +
        this.equipment.statistics.connection2gCountDay2 +
        this.equipment.statistics.connection2gCountDay3 +
        this.equipment.statistics.connection2gCountDay4 +
        this.equipment.statistics.connection2gCountDay5 +
        this.equipment.statistics.connection2gCountDay6 +
        this.equipment.statistics.connection2gCountDay7;

      const connectionsKo = this.equipment.statistics.connectionKoCountDay1 +
        this.equipment.statistics.connectionKoCountDay2 +
        this.equipment.statistics.connectionKoCountDay3 +
        this.equipment.statistics.connectionKoCountDay4 +
        this.equipment.statistics.connectionKoCountDay5 +
        this.equipment.statistics.connectionKoCountDay6 +
        this.equipment.statistics.connectionKoCountDay7;

      const total = connections2g + connectionsNbiot + connectionsKo;

      if (total > 0) {
        this.connectionsTypePercentage[0] = Math.round((connectionsNbiot / total) * 100);
        this.connectionsTypePercentage[1] = Math.round((connections2g / total) * 100);
        this.connectionsTypePercentage[2] = Math.round((connectionsKo / total) * 100);
      }

      // Media diaria
      this.connectionMedia = Math.round((connections2g + connectionsNbiot) / 7);


      // Calcular calidad de las conexiones

      const qualityConnectionsNgbiot = [];

      qualityConnectionsNgbiot.push(this.calculateSignalQualityNgbiot(this.equipment.statistics.qualityNbiotCountDay1));
      qualityConnectionsNgbiot.push(this.calculateSignalQualityNgbiot(this.equipment.statistics.qualityNbiotCountDay2));
      qualityConnectionsNgbiot.push(this.calculateSignalQualityNgbiot(this.equipment.statistics.qualityNbiotCountDay3));
      qualityConnectionsNgbiot.push(this.calculateSignalQualityNgbiot(this.equipment.statistics.qualityNbiotCountDay4));
      qualityConnectionsNgbiot.push(this.calculateSignalQualityNgbiot(this.equipment.statistics.qualityNbiotCountDay5));
      qualityConnectionsNgbiot.push(this.calculateSignalQualityNgbiot(this.equipment.statistics.qualityNbiotCountDay6));
      qualityConnectionsNgbiot.push(this.calculateSignalQualityNgbiot(this.equipment.statistics.qualityNbiotCountDay7));

      const mediaNgbiot = this.calculateMedia(qualityConnectionsNgbiot);

      const qualityConnections2g = [];

      qualityConnections2g.push(this.calculateSignalQuality2g(this.equipment.statistics.quality2gCountDay1));
      qualityConnections2g.push(this.calculateSignalQuality2g(this.equipment.statistics.quality2gCountDay2));
      qualityConnections2g.push(this.calculateSignalQuality2g(this.equipment.statistics.quality2gCountDay3));
      qualityConnections2g.push(this.calculateSignalQuality2g(this.equipment.statistics.quality2gCountDay4));
      qualityConnections2g.push(this.calculateSignalQuality2g(this.equipment.statistics.quality2gCountDay5));
      qualityConnections2g.push(this.calculateSignalQuality2g(this.equipment.statistics.quality2gCountDay6));
      qualityConnections2g.push(this.calculateSignalQuality2g(this.equipment.statistics.quality2gCountDay7));

      const media2g = this.calculateMedia(qualityConnections2g);

      this.qualityConnectionsPercentage[0] = mediaNgbiot;
      this.qualityConnectionsPercentage[1] = media2g;

    }
  }

  private createChartUseDaily() {

    if (this.equipment && this.equipment.statistics) {

      const statisticsData = [];

      statisticsData.push({ value: this.equipment.statistics.accessCountDay1, backgroundColor: '#76BC21' });
      statisticsData.push({ value: this.equipment.statistics.accessCountDay2, backgroundColor: '#76BC21' });
      statisticsData.push({ value: this.equipment.statistics.accessCountDay3, backgroundColor: '#76BC21' });
      statisticsData.push({ value: this.equipment.statistics.accessCountDay4, backgroundColor: '#76BC21' });
      statisticsData.push({ value: this.equipment.statistics.accessCountDay5, backgroundColor: '#76BC21' });
      statisticsData.push({ value: this.equipment.statistics.accessCountDay6, backgroundColor: '#76BC21' });
      statisticsData.push({ value: this.equipment.statistics.accessCountDay7, backgroundColor: '#76BC21' });

      const maxItemData = statisticsData.reduce((prev, current) => (current.value > prev.value) ? current : prev);

      maxItemData.backgroundColor = '#55802E';

      const barChartData: ChartData<'bar'> = {
        labels: ['L', 'M', 'X', 'J', 'V', 'S', 'D'],
        datasets: [
          {
            label: '',
            data: statisticsData.map(item => item.value),
            backgroundColor: statisticsData.map(item => item.backgroundColor),
            borderWidth: 0,
            barPercentage: 1,
          }
        ]
      };

      this.chartUseDaily = new Chart(this.chartUseDailyLayer.nativeElement, {
        type: 'bar',
        data: barChartData,
        options: this.chartBarDailyOptions,
      });

    }
  }

  private createChartUseHorary() {

    if (this.equipment && this.equipment.statistics) {

      const statisticsData = [];

      statisticsData.push({ value: this.equipment.statistics.accessCountInterval1, backgroundColor: '#76BC21' });
      statisticsData.push({ value: this.equipment.statistics.accessCountInterval2, backgroundColor: '#76BC21' });
      statisticsData.push({ value: this.equipment.statistics.accessCountInterval3, backgroundColor: '#76BC21' });
      statisticsData.push({ value: this.equipment.statistics.accessCountInterval4, backgroundColor: '#76BC21' });
      statisticsData.push({ value: this.equipment.statistics.accessCountInterval5, backgroundColor: '#76BC21' });
      statisticsData.push({ value: this.equipment.statistics.accessCountInterval6, backgroundColor: '#76BC21' });

      const maxItemData = statisticsData.reduce((prev, current) => (current.value > prev.value) ? current : prev);

      maxItemData.backgroundColor = '#55802E';

      const barChartData: ChartData<'bar'> = {
        labels: ['00-04', '04-08', '08-12', '12-16', '16-20', '20-24'],
        datasets: [
          {
            label: '',
            data: statisticsData.map(item => item.value),
            backgroundColor: statisticsData.map(item => item.backgroundColor),
            borderWidth: 0,
            barPercentage: 1,
          }
        ]
      };

      this.chartUseHorary = new Chart(this.chartUseHoraryLayer.nativeElement, {
        type: 'bar',
        data: barChartData,
        options: this.chartBarHoraryOptions,
      });
    }
  }

  private createChartQualityConnection() {

    const qualityChartData: ChartData<'bar'> = {
      labels: ['NBIoT', '2G'], // Etiquetas del eje Y
      datasets: [
        {
          label: 'Dispositivo',
          data: this.qualityConnectionsPercentage, // Datos para 'Dispositivo'
          backgroundColor: '#8bc34a', // Color verde
          borderRadius: 6, // Redondeo de las esquinas
          barThickness: 8,
        },
        {
          label: 'Parque',
          data: this.fleetQualityConnectionsPercentage, // Datos para 'Parque'
          backgroundColor: '#9e9e9e', // Color gris
          borderRadius: 6, // Redondeo de las esquinas
          barThickness: 8,
        },
      ]
    };

    this.chartQualityConnection = new Chart(this.chartQualityConnectionLayer.nativeElement, {
      type: 'bar',
      data: qualityChartData,
      options: this.qualityBarChartOptions,
    });

  }

  private createChartConnectionsTypeDevice() {

    const doughnutChartData: ChartData<'doughnut'> = {
      datasets: [{
        data: this.connectionsTypePercentage,
        backgroundColor: [
          '#55802E',
          '#76BC21',
          '#B2B2B2',
        ],
      }]
    };

    this.chartConnectionsTypeDevice = new Chart(this.chartConnectionsTypeDeviceLayer.nativeElement, {
      type: 'doughnut',
      data: doughnutChartData,
      options: this.chartDoughnutOptions,
    });

  }

  private createChartConnectionsTypeFleet() {

    const doughnutChartData: ChartData<'doughnut'> = {
      datasets: [{
        data: this.fleetConnectionsTypePercentage,
        backgroundColor: [
          '#55802E',
          '#76BC21',
          '#B2B2B2',
        ],
      }]
    };

    this.chartConnectionsTypeDevice = new Chart(this.chartConnectionsTypeFleetLayer.nativeElement, {
      type: 'doughnut',
      data: doughnutChartData,
      options: this.chartDoughnutOptions,
    });

  }

  private calculateSignalQualityNgbiot(value: number): number {
    if (value > -65 && value <= -51) {
      return 100;
    } else if (value > -75 && value <= -65) {
      return 75;
    } else if (value > -85 && value <= -75) {
      return 50;
    } else if (value > -90 && value <= -85) {
      return 25;
    } else if (value >= -113 && value <= -90) {
      return 0;
    } else {
      return 0;
    }
  }

  private calculateSignalQuality2g(value: number): number {
    if (value > -70 && value <= -51) {
      return 100;
    } else if (value > -85 && value <= -70) {
      return 75;
    } else if (value > -100 && value <= -85) {
      return 50;
    } else if (value > -110 && value <= -100) {
      return 25;
    } else if (value >= -113 && value <= -100) {
      return 0;
    } else {
      return 0;
    }
  }

  private calculateMedia(values: number[]): number {
    return values.reduce((acc, value) => acc + value, 0) / values.length;
  }

  private calculateFleetStats() {

    // Accesos (Aperturas?)
    this.fleetAccessCountMedia = this.fleetStatistics.totalAccessCount;

    // Calcular media aperturas
    this.accessMediaUse = Math.round(((this.accessCountMedia - this.fleetAccessCountMedia) / this.fleetAccessCountMedia) * 100);
    if (isNaN(this.accessMediaUse)) {
      this.accessMediaUse = 0;
    }

    if (this.accessMediaUse === 0) {
      this.accessMediaUseText = 'Igual Uso';
    } else if (this.accessMediaUse > 0) {
      this.accessMediaUseText = 'Mayor Uso';
    } else if (this.accessMediaUse < 0) {
      this.accessMediaUseText = 'Menor Uso';
    }

    // Calcular conexiones por tipo
    const total = this.fleetStatistics.totalConnection2gCount +
      this.fleetStatistics.totalConnectionNbiotCount +
      this.fleetStatistics.totalConnectionKoCount;

    if (total > 0) {
      this.fleetConnectionsTypePercentage[0] = Math.round((this.fleetStatistics.totalConnectionNbiotCount / total) * 100);
      this.fleetConnectionsTypePercentage[1] = Math.round((this.fleetStatistics.totalConnection2gCount / total) * 100);
      this.fleetConnectionsTypePercentage[2] = Math.round((this.fleetStatistics.totalConnectionKoCount / total) * 100);
    }

    // Calcular calidad de las conexiones

    const mediaNgbiot = this.calculateSignalQualityNgbiot(this.fleetStatistics.totalQualityNbiotCount);
    const media2g = this.calculateSignalQuality2g(this.fleetStatistics.totalQuality2gCount);

    this.fleetQualityConnectionsPercentage[0] = mediaNgbiot;
    this.fleetQualityConnectionsPercentage[1] = media2g;
  }

}
