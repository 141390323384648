// Angular modules
import { ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, OnDestroy, Output } from '@angular/core';
import { OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { IMapEquipment } from '../../models/map-equipment.interface';
import { EquipmentService } from '@equipments/equipments-mgmt/services/equipment.service';
import { delay, forkJoin, Subject, takeUntil } from 'rxjs';
import { MapEquipment } from '../../models/map-equipment.model';
import { MapEquipmentService } from '../../services/map-equipment.service';
import { IFleetStatistics } from '../../models/fleet-statistics.interface';
import { Reception } from '@mqtt/receptions/models';
import { Request } from '@mqtt/requests/models';
import { DeviceService } from '@equipments/devices-mgmt/services/device.service';
import { IDeviceCardUsage } from '@equipments/devices-mgmt/models';

@Component({
  selector: 'lockbin-equipment-info-extend',
  templateUrl: './equipment-info-extend.component.html',
  // styleUrls: ['./equipment-info-extend.component.scss'],
})
export class EquipmentInfoExtendComponent implements OnInit, OnDestroy {
  @HostBinding('class') componentCssClass = 'equipment-info-extend';

  @Output() closeEvent = new EventEmitter<string>();
  @Output() changeTab = new EventEmitter<string>();

  today!: Date;

  activeTab: number = 1;

  equipment!: MapEquipment | undefined;
  fleetStatistics!: IFleetStatistics;
  // communications!: Array<Reception>;
  issues!: any[];
  // deviceUsage!: IDeviceCardUsage[];

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    protected logger: NGXLogger,
    private mapEquipmentService: MapEquipmentService,
    private deviceService: DeviceService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.today = new Date();
  }

  ngOnInit(): void {
    this.logger.debug('EquipmentInfoExtendComponent:ngOnInit');

    // Esto se carga al cargar el mapa
    this.loadFleetStatistics();

  }

  initEquipment(equipment: MapEquipment) {

    // Establecemos la variable con la información del equipo
    this.equipment = equipment;

    // this.loadUsage();

    this.loadIssues();

    // this.loadCommunications();

  }

  clearEquipment() {

    this.equipment = undefined;

  }

  openInfoEquipment(equipmentMap: IMapEquipment) {
    this.logger.debug('openInfoEquipment', 'Cargamos toda la información del Equipo:', equipmentMap.id);
  }

  closeInfo() {
    this.equipment = undefined;

    this.closeEvent.emit('Close');
  }

  doClickClose() {
    this.closeInfo();
  }

  resetTabActive() {
    this.activeTab = 1;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private loadFleetStatistics() {
    this.mapEquipmentService.findAllStatistics().pipe(
      takeUntil(this.unsubscribe$),
      // tap((fleetStatistics) => {

      //   this.setDummyData(fleetStatistics);

      // })
      ).subscribe((stats) => {
        if (stats) {
          this.logger.debug('loadFleetStatistics', 'Cargadas las estadísticas globales', stats);
          this.fleetStatistics = stats;
        }
      }
    );
  }

  // private loadUsage() {

  //   if (this.equipment) {

  //     this.deviceService.getDeviceCardUsage(this.equipment.device.id).pipe(
  //       takeUntil(this.unsubscribe$)
  //     ).subscribe((data) => {

  //       this.deviceUsage = data;
  //       console.log('Uso', this.deviceUsage);

  //     });
  //   }

  // }

  private loadIssues() {

    if (this.equipment) {

      this.deviceService.getDeviceAlert(this.equipment.device.id).pipe(
        takeUntil(this.unsubscribe$),
        // delay(20000),
      ).subscribe({
        next: (data) => {

          this.issues = data;

          console.log('Incidencias', this.issues);

        },
        error: (err) => console.error(err),
      });

    }

  }

  // private loadCommunications() {

  //   if (this.equipment) {

  //     const $reception = this.deviceService.getDeviceReception(this.equipment.device.id);
  //     const $request = this.deviceService.getDeviceRequest(this.equipment.device.id);

  //     forkJoin([$reception, $request]).pipe(
  //       takeUntil(this.unsubscribe$)
  //     ).subscribe({
  //       next: (data) => {

  //         this.communications = [];

  //         if (data[0]) {
  //           this.communications.push(...data[0]);
  //         }

  //         // if (data[1]) {
  //         //   this.communications.push(...data[1]);
  //         // }

  //         console.log('Comunicaciones', this.communications);

  //       },
  //       error: (err) => console.error(err),
  //     });

  //   }

  // }

}
