import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Modules
import { SharedModule } from '../shared/shared.module';
import { StaticModule } from '../static/static.module';
import { StatusMapComponent } from './status-map.component';
import { StatusMapRoutingModule } from './status-map-routing.module';
import { MapViewComponent } from './pages/map-view/map-view.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { MapSidebarComponent } from './components/map-sidebar/map-sidebar.component';
import { EquipmentInfoComponent } from './components/equipment-info/equipment-info.component';
import { MapFilterItemComponent } from './components/map-filter-item/map-filter-item.component';
import { MapIssuesComponent } from './components/map-issues/map-issues.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { MapRecommendationsComponent } from './components/map-recommendations/map-recommendations.component';
import { MapFilterComponent } from './components/map-filter/map-filter.component';
import { MapSidebarHeaderComponent } from './components/map-sidebar-header/map-sidebar-header.component';
import { MapFilterItemStaticComponent } from './components/map-filter-item-static/map-filter-item-static.component';
import { EquipmentInfoExtendComponent } from './components/equipment-info-extend/equipment-info-extend.component';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { EquipmentInfoGeneralComponent } from './components/equipment-info-general/equipment-info-general.component';
import { EquipmentInfoUseComponent } from './components/equipment-info-use/equipment-info-use.component';
import { EquipmentInfoCommsComponent } from './components/equipment-info-comms/equipment-info-comms.component';
import { EquipmentInfoIssuesComponent } from './components/equipment-info-issues/equipment-info-issues.component';


@NgModule({
  declarations: [
    StatusMapComponent,
    MapViewComponent,

    MapSidebarComponent,
    EquipmentInfoComponent,
    MapFilterComponent,
    MapFilterItemComponent,
    MapFilterItemStaticComponent,
    MapIssuesComponent,
    MapRecommendationsComponent,
    MapSidebarHeaderComponent,
    EquipmentInfoExtendComponent,
    EquipmentInfoGeneralComponent,
    EquipmentInfoUseComponent,
    EquipmentInfoCommsComponent,
    EquipmentInfoIssuesComponent,
  ],
  imports: [
    CommonModule,
    StatusMapRoutingModule,

    // Internal modules
    SharedModule,
    StaticModule,

    GoogleMapsModule,

    AngularSvgIconModule,
    NgbNavModule,
  ]
})
export class StatusMapModule { }
