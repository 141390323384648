// Angular modules
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { forkJoin, of, Subject, takeUntil, tap } from 'rxjs';
import { MapEquipment } from '../../models/map-equipment.model';
import { ArcElement, BarController, BarElement, CategoryScale, Chart, ChartConfiguration, ChartData, DoughnutController, Legend, LinearScale, LineController, LineElement, PointElement, Title, Tooltip } from 'chart.js';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { MapEquipmentService } from '../../services/map-equipment.service';
import { IFleetStatistics } from '../../models/fleet-statistics.interface';
import { DeviceService } from '@equipments/devices-mgmt/services/device.service';
import { IDeviceCardUsage } from '@equipments/devices-mgmt/models';
import { CardService } from '@cards/cards-mgmt/services/card.service';

@Component({
  selector: 'lockbin-equipment-info-use',
  templateUrl: './equipment-info-use.component.html',
  // styleUrls: ['./equipment-info-use.component.scss'],
})
export class EquipmentInfoUseComponent implements OnInit, AfterViewInit, OnDestroy {
  @HostBinding('class') componentCssClass = 'equipment-info-use';

  @Input() equipment: MapEquipment | undefined;
  @Input() fleetStatistics!: IFleetStatistics;
  // @Input() deviceUsage!: IDeviceCardUsage[];

  @ViewChild('chartUseDailyLayerRef') chartUseDailyLayer!: ElementRef;

  today!: Date;
  deviceUsage!: IDeviceCardUsage[];
  itemsPerPage = 10;
  currentPage = 1;
  totalItems = 0;

  chartUseDaily: any;

  accessCountMedia!: number;
  fleetAccessCountMedia!: number;

  accessMediaUse!: number;
  accessMediaUseText!: string; // Menor Uso | Mayor Uso | Igual Uso

  isRefreshing = false;
  renderHistory = false;

  private chartBarDailyOptions: ChartConfiguration['options'] = {
    responsive: true,
    aspectRatio: 2.8,
    scales: {
      x: {
        type: 'category',
        grid: {
          display: false,
        },
        border: {
          display: true,
          color: '#000',
        },
        ticks: {
          font: {
            family: '"Manrope", Arial, sans-serif',
            size: 10,
            lineHeight: 1,
          },
          color: '#8e8e8e',
        }
      },
      y: {
        type: 'linear',
        beginAtZero: true,
        ticks: {
          display: false,
        },
        border: {
          display: false,
        },
        grid: {
          display: false,
        }
      }
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        bodyAlign: 'center',
        yAlign: 'bottom',
        callbacks: {
          title: () => { return ''; },
        }
      },
      datalabels: {
        anchor: (context) => {
          const dataset = context.dataset.data as number[];
          const value = context.dataset.data[context.dataIndex] as number;
          const maxValue = Math.max(...dataset);
          const minValue = Math.min(...dataset);

          // Cambiar anchor dependiendo de si es el valor más alto o más bajo
          if (value === maxValue) {
            return 'center';
          } else if (value === minValue) {
            return 'end';
          }
          return 'center';
        },
        align: (context) => {
          const dataset = context.dataset.data as number[];
          const value = context.dataset.data[context.dataIndex] as number;
          const maxValue = Math.max(...dataset);
          const minValue = Math.min(...dataset);

          // Cambiar align dependiendo de si es el valor más alto o más bajo
          if (value === maxValue) {
            return 'center';
          } else if (value === minValue) {
            return 'end';
          }
          return 'center'; // Alineación por defecto
        },
        color: (context) => {
          const dataset = context.dataset.data as number[];
          const value = context.dataset.data[context.dataIndex] as number;
          const maxValue = Math.max(...dataset);
          const minValue = Math.min(...dataset);

          // Cambiar color dependiendo de si es el valor más alto o más bajo
          if (value === maxValue) {
            return '#fff';
          } else if (value === minValue) {
            return '#999';
          }
          return '#fff';
        },
        font: {
          size: 10,
        },
        display: (context) => {
          const dataset = context.dataset.data as number[];
          const value = context.dataset.data[context.dataIndex] as number;
          const maxValue = Math.max(...dataset);
          const minValue = Math.min(...dataset);

          // Mostrar solo si el valor es el máximo o el mínimo
          return value === maxValue || value === minValue;
        },
      },
    }
  };

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    protected logger: NGXLogger,
    private deviceService: DeviceService,
    private cardService: CardService,
    private changeDetectorRef: ChangeDetectorRef) {

      this.today = new Date();

      Chart.register(LineController, LineElement, PointElement, BarElement, BarController, CategoryScale, LinearScale, BarElement, DoughnutController, ArcElement, Title, Tooltip, Legend, DataLabelsPlugin);

  }

  ngOnInit(): void {
    this.logger.debug('EquipmentInfoUseComponent:ngOnInit');

    // Obtenemos los datos
    this.loadUse();
  }

  ngAfterViewInit() {

    this.logger.debug('EquipmentInfoUseComponent:ngAfterViewInit');

    this.calculateStats();

    this.calculateFleetStats();

    this.createChartUseDaily();

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  doChangePagination(page: number) {

    this.currentPage = page;

    this.loadUse();

  }

  doClickRefreshUse() {
    this.refreshUse();
  }

  private fillRelatedAttributes() {

    this.renderHistory = false;

    const cardRequests = this.deviceUsage.map(usage => {
      if (usage.cardId) {
        return this.cardService.findById(usage.cardId).pipe(
          tap(cardData => usage.card = cardData)
        )
      } else {
        return of(null);
      }
    });

    forkJoin(cardRequests).subscribe({
      next: () => {
        this.renderHistory = true;
      },
      error: error => {
        console.error('Error al procesar las solicitudes:', error);
      }
    });
  }

  private calculateStats() {

    if (this.equipment && this.equipment.statistics) {

      // Accesos (Aperturas?)
      this.accessCountMedia = Math.round((this.equipment.statistics.accessCountDay1 +
        this.equipment.statistics.accessCountDay2 +
        this.equipment.statistics.accessCountDay3 +
        this.equipment.statistics.accessCountDay4 +
        this.equipment.statistics.accessCountDay5 +
        this.equipment.statistics.accessCountDay6 +
        this.equipment.statistics.accessCountDay7) / 7);

    }
  }

  private createChartUseDaily() {

    if (this.equipment && this.equipment.statistics) {

      const statisticsData = [];

      statisticsData.push({ value: this.equipment.statistics.accessCountDay1, backgroundColor: '#76BC21' });
      statisticsData.push({ value: this.equipment.statistics.accessCountDay2, backgroundColor: '#76BC21' });
      statisticsData.push({ value: this.equipment.statistics.accessCountDay3, backgroundColor: '#76BC21' });
      statisticsData.push({ value: this.equipment.statistics.accessCountDay4, backgroundColor: '#76BC21' });
      statisticsData.push({ value: this.equipment.statistics.accessCountDay5, backgroundColor: '#76BC21' });
      statisticsData.push({ value: this.equipment.statistics.accessCountDay6, backgroundColor: '#76BC21' });
      statisticsData.push({ value: this.equipment.statistics.accessCountDay7, backgroundColor: '#76BC21' });

      const maxItemData = statisticsData.reduce((prev, current) => (current.value > prev.value) ? current : prev);

      maxItemData.backgroundColor = '#55802E';

      const barChartData: ChartData<'bar'> = {
        labels: ['L', 'M', 'X', 'J', 'V', 'S', 'D'],
        datasets: [
          {
            label: '',
            data: statisticsData.map(item => item.value),
            backgroundColor: statisticsData.map(item => item.backgroundColor),
            borderWidth: 0,
            barPercentage: 1,
          }
        ]
      };

      this.chartUseDaily = new Chart(this.chartUseDailyLayer.nativeElement, {
        type: 'bar',
        data: barChartData,
        options: this.chartBarDailyOptions,
      });

    }
  }

  private calculateFleetStats() {

    // Accesos (Aperturas?)
    this.fleetAccessCountMedia = this.fleetStatistics.totalAccessCount;

    // Calcular media aperturas
    this.accessMediaUse = Math.round(((this.accessCountMedia - this.fleetAccessCountMedia) / this.fleetAccessCountMedia) * 100);
    if (this.accessMediaUse === 0) {
      this.accessMediaUseText = 'Igual Uso';
    } else if (this.accessMediaUse > 0) {
      this.accessMediaUseText = 'Mayor Uso';
    } else {
      this.accessMediaUseText = 'Menor Uso';
    }

  }

  private getParamsQuery() {

    return {
      pageNo: this.currentPage > 0 ? this.currentPage - 1 : 0,
      pageSize: this.itemsPerPage,
      sort: 'usageAt,DESC',
    };

  }


  private loadUse() {

    if (this.equipment) {

      const paramsQuery = this.getParamsQuery();

      this.deviceService.getDeviceCardUsage(this.equipment.device.id, paramsQuery).pipe(
        takeUntil(this.unsubscribe$)
      ).subscribe({
        next: (data) => {

          this.deviceUsage = [];
          this.deviceUsage = data.items;

          console.log('Uso', this.deviceUsage);

          this.isRefreshing = false;

          this.fillRelatedAttributes();

          this.totalItems = data.totalItems;

        },
        error: (err) => {
          this.isRefreshing = false;
          console.error(err)
        }
      });

    }

  }

  private refreshUse() {

    if (this.equipment && !this.isRefreshing) {

      this.isRefreshing = true;

      this.currentPage = 1;

      this.loadUse();

    }
  }
}
