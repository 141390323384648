
<div class="equipment-info-general__grid" *ngIf="equipment">

  <div class="equipment-info-general__gap">

    <div class="d-flex flex-column justify-content-between h-100">
      <div class="pt-2 mb-3">
        <div class="h4 mb-1 text-danger text-uppercase">Incidencias:</div>
        <div class="d-inline-block bg-white px-2 border border-danger rounded-4 fs-12px">Última semana</div>
      </div>

      <div class="d-flex align-items-end mb-2">

        <div class="flex-shrink-0 me-2">
        </div>
        <div class="fs-13px lh-sm mb-1">

        </div>

      </div>

    </div>

  </div>

  <div class="equipment-info-general__gap">

    <div class="equipment-data__group h-100">
      <div class="equipment-data__title">Recuento de incidencias</div>
      <div class="equipment-data__content px-2">

        <div class="py-1">
          <div class="px-2 pt-1">
            <div class="d-flex justify-content-center">

            </div>
          </div>
        </div>

      </div>
    </div>

  </div>


</div>

<div class="border-top bg-white p-4 pe-5 pb-1" *ngIf="equipment && issues">

  <div class="">
    <div class="mb-4 d-flex justify-content-between">
      <div class="">
        <span class="h4 text-uppercase">Historial de incidencias</span> <span class="fs-13px text-gray ms-3">última semana</span>
      </div>
      <div>
        <button class="btn btn-icon btn-gray btn-sm pe-1" (click)="doClickRefreshIssues()" [disabled]="isRefreshing">
          <em class="icon ni ni-reload"></em>
          Refrescar
        </button>
      </div>
    </div>
    <div class="equipment-table" *ngIf="issues">

      <table class="table table-borderless">
        <colgroup>
          <col style="width: 7%;">
          <col style="width: 18%;">
          <col style="width: 13%;">
          <col style="width: 16%;">
          <col style="width: 12%;">
          <col style="width: 15%;">
          <col style="width: 20%;">
        </colgroup>
        <thead>
          <tr>
            <th scope="col" colspan="2">Tipo de incidencia</th>
            <th scope="col">Estado</th>
            <th scope="col">Fecha</th>
            <th scope="col">Hora</th>
            <th scope="col">Tiempo activa</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let issue of issues">
            <td>
              <svg-icon class="map-issues__item__icon__svg" [src]="'assets/img/status-map/alerts/alert-' + issue.alert.code + '.svg'"></svg-icon>
            </td>
            <td>{{ issue.alert.name }}</td>
            <td [innerHTML]="issue.discarded_at ? 'Resuelta' : '<span>Activa</span>'"></td>
            <td>{{ issue.alertDate | date: 'dd/MM/yyyy' }}</td>
            <td>{{ issue.alertDate | date: 'H:mm' }}</td>
            <td>-</td>
            <td>-</td>
          </tr>
        </tbody>
      </table>

    </div>
  </div>

</div>
