// Angular modules
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Subject, takeUntil, tap } from 'rxjs';
import { MapEquipment } from '../../models/map-equipment.model';
import { ArcElement, BarController, BarElement, CategoryScale, Chart, ChartConfiguration, ChartData, DoughnutController, Legend, LinearScale, LineController, LineElement, PointElement, Title, Tooltip } from 'chart.js';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { MapEquipmentService } from '../../services/map-equipment.service';
import { IFleetStatistics } from '../../models/fleet-statistics.interface';
import { DeviceService } from '@equipments/devices-mgmt/services/device.service';

@Component({
  selector: 'lockbin-equipment-info-issues',
  templateUrl: './equipment-info-issues.component.html',
  // styleUrls: ['./equipment-info-issues.component.scss'],
})
export class EquipmentInfoIssuesComponent implements OnInit, AfterViewInit, OnDestroy {
  @HostBinding('class') componentCssClass = 'equipment-info-issues';

  @Input() equipment: MapEquipment | undefined;
  @Input() fleetStatistics!: IFleetStatistics;
  @Input() issues!: any[];

  today!: Date;

  isRefreshing = false;

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    protected logger: NGXLogger,
    private deviceService: DeviceService,
    private changeDetectorRef: ChangeDetectorRef) {

      this.today = new Date();

      Chart.register(LineController, LineElement, PointElement, BarElement, BarController, CategoryScale, LinearScale, BarElement, DoughnutController, ArcElement, Title, Tooltip, Legend, DataLabelsPlugin);

  }

  ngOnInit(): void {
    this.logger.debug('EquipmentInfoIssuesComponent:ngOnInit');
  }

  ngAfterViewInit() {
    this.logger.debug('EquipmentInfoIssuesComponent:ngAfterViewInit');

    // this.calculateStats();

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  doClickRefreshIssues() {
    this.refreshIssues();
  }

  private refreshIssues() {

    if (this.equipment && !this.isRefreshing) {

      this.isRefreshing = true;

      this.deviceService.getDeviceAlert(this.equipment.device.id).pipe(
        takeUntil(this.unsubscribe$)
      ).subscribe({
        next: (data) => {

          this.issues = [];
          this.issues = data;

          console.log('Incidencias', this.issues);

          this.isRefreshing = false;

        },
        error: (err) => {
          this.isRefreshing = false;
          console.error(err)
        }
      });

    }
  }
}
